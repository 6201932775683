import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { ContextoUsuario } from "../../../security/ContextoUsuario";
import { useFormulario } from "../../../utils/hooks/useFormulario";

import { propUsuario } from "../../../models/MisInterfaces";

import logoEnap from "../../../../assets/image/logo_enap.png";
import ApiBack from "../../../utils/domines/ApiBack";
import ServicioPrivado from "../../../services/ServicioPrivado";
import Dependencia from '../../../models/Dependencia';
import { MesajeError } from "../../../utils/tools/MesajeError";
import CreaPersonaBloqueada from "../../../models/CreaPersonaBloqueada";

// Para los mensajes, no olvidar !!!
// *******************************************************************
export const BlockUser = () => {
  // Variables
  // *******************************************************************
  const navigate = useNavigate();
  type formHtml = React.FormEvent<HTMLFormElement>;
  const { actualizar } = useContext(ContextoUsuario) as propUsuario;
  const [enProceso, setEnProceso] = useState<boolean>(false);
  const [readyUser, setReadyUser] = useState(false);
  const [validData, setValidData] = useState<boolean>(false);

  const [imagePreview, setImagePreview] = useState<any>("");
  const [base64, setBase64] = useState<string>();

  const [arregloDependencia, setArregloDependencia] = useState<Dependencia[]>([]);


  let { idUserSearch } = useParams();
  const [textToShow, setTextToShow] = useState<string>("");

  // Manejo de formulario con Hook personalizado (sin librerias externas)
  // *******************************************************************
  let {
    idPersona,
    fechaBloqueo,
    motivoBloqueo,
    status,
    dobleEnlace,
    objeto,
  } = useFormulario<CreaPersonaBloqueada>(
    new CreaPersonaBloqueada(0,new Date(),"",0)
  );


  // Función flecha para resetear variables y limpiar cajas del formulario
  // *******************************************************************
  const limpiarCajas = (formulario: HTMLFormElement) => {
    formulario.reset();
    objeto.motivoBloqueo = "";

    //formulario.tipoDocumento.value = "1";
    formulario.motivoBloqueo.value = "";

    formulario.classList.remove("was-validated");
  };


  const verifyForm = async (fh: formHtml) => {
    /**
     * previene acciones por defecto de los formularios html
     */
    fh.preventDefault();
    setEnProceso(true);
    /**
     * se captura la información del formulario actual
     */
    const formActual = fh.currentTarget;
    formActual.classList.add("was-validated");
    if (formActual.checkValidity() === false) {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
    } else {
      //console.log("Conexión al backend");
      //const fromBackend = await ServicioPublico.createUser(objeto);
      //console.log(fromBackend);

      // Código para crear el usuario consumiendo servicio del back y usando sha512
      // *******************************************************************
      const url = ApiBack.BLOQUEO_AGREGAR;
      const resultado = await ServicioPrivado.peticionPOST(url, objeto); ;//await ServicioPublico.crearUsuario(objeto);
      
      if (resultado) {
        navigate("/dashboard/listUserContra");

//        navigate("/login");
        setEnProceso(false);
      } else {
        //limpiarCajas(formActual);
        MesajeError("No se puede actualizar el estado del usuario");
        //MensajeToastify("Error", "No se puede crear el usuario. Correo o perfil incorrectos.", 7000);
      }
    }
  };
  


  const obtenerUsuario = async () => {

    const url = ApiBack.USUARIO_OBTENERUNO + "/" + idUserSearch;

    const resultado = await ServicioPrivado.peticionGET(url);

    if (resultado.idPersona) 
    {
    
      objeto.idPersona = resultado.idPersona;
      objeto.status = resultado.status;
      setTextToShow("Documento: " + resultado.documento + ", Nombre: " + resultado.nombres +" " + resultado.apellidos +
      ", Telefono: " + resultado.telefono + ", Correo: " + resultado.correo);

      setValidData(true);
    } else {
      
      setValidData(false);
    }
  
};


  
  useEffect(() => {
    if (!readyUser) {
      obtenerUsuario();
    }
  }, []);


  return (
    <div>
      <main>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src={logoEnap} alt="" />
                      <span className="d-none d-lg-block">
                        Sistema de Ingreso ENAP
                      </span>
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Editar datos de estado de Bloqueo
                        </h5>
                      </div>
                      <div className="pt-4 pb-2">
                        <p className="card-title text-center pb-0 fs-4">
                          {textToShow}
                        </p>
                      </div>

                      <Form
                        noValidate
                        className="row g-3"
                        validated={enProceso}
                        onSubmit={verifyForm}
                      >

<div className="col-12">
                          <Form.Group controlId="status">
                            <Form.Label>Estado del usuario</Form.Label>

                            <Form.Select
                              required
                              name="status"
                              value={status}
                              onChange={dobleEnlace}
                              className="form-control"
                            >
                              <option value="">
                                Seleccione el estado del usuario
                              </option>
                              <option value={0}>Inactivo</option>
                              <option value={1}>Activo</option>
                              <option value={2}>Bloqueado</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Seleccione el estado del usuario
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="motivoBloqueo">
                            <Form.Label>Justificación</Form.Label>

                            <Form.Control
                              required
                              as="textarea"
                              name="motivoBloqueo"
                              className="form-control"
                              value={motivoBloqueo}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                            Se debe ingresar una justificación
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Actualizar
                          </button>
                        </div>
                      </Form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};
