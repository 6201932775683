

import { Link } from "react-router-dom";
import { ListAutorizaByPerson } from "./ListAutorizaByPerson";

export const ShowListAutorizaciones = () => {
  

  //const [dataIdUsuario, setDataIdUsuario] = useState<string>("hola");

  
  
 
  return (
    <main id="main" className="main">

      
      <div className="col-lg-11 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
        <Link to={"/dashboard/newPermiso/"} title="Nueva autorización">
                        <i
                          className="fa-regular fa-address-card fa-2xl"
                          style={{ color: "#0a08bd" }}
                          aria-label="Crear nueva autorización"
                        ></i>
                        </Link>
          <span className="fs-4">Autorizaciones realizadas</span>
        </header>
             
              <p className="fs-6 center">
               {ListAutorizaByPerson()}
              </p>

          <div className="col-lg-11 mx-auto p-4 py-md-5">

          </div>
      </div>
    </main>
  );
};
