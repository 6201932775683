import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";

import ApiBack from "../../../utils/domines/ApiBack";  

import ServicioPrivado from "../../../services/ServicioPrivado";
import { useFormulario } from "../../../utils/hooks/useFormulario";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import PersonaCompleta from "../../../models/PersonaCompleta";
import FilterAutoriza from "../../../models/FilterAutoriza";
import { MesajeError } from "../../../utils/tools/MesajeError";
import { MesajeSuccess } from "../../../utils/tools/MesajeSuccess";
import { FormatFecha } from "../../../utils/tools/ToolsDate";
import jwtDecode from "jwt-decode";
import InfoSearch from "../../../models/InfoSearch";

export const ListUser = () => {
  

  
  //const [fecIni, setFecIni] = useState <Date>(new Date);
  //const [fecFin, setFecFin] = useState <Date>(new Date);
  const [fecAct, setFecAct] = useState <Date>(new Date);
  const [show, setShow] = useState(false);
  const [readyUser, setReadyUser] = useState(false);
  const handleClose = () => setShow(false);
  const [enProceso, setEnProceso] = useState<boolean>(false);
  type formHtml = React.FormEvent<HTMLFormElement>;

  let elToken = "";
  let idUsuarioCons =  "";
  let idDep = 0;
  let idDiv = 0;
  let perfil = "";
  if (localStorage.getItem("tokenSingenap")) {
    elToken = String(localStorage.getItem("tokenSingenap"));
    const objJWTRecibido: any = jwtDecode(elToken);
    idUsuarioCons =  String(objJWTRecibido.idUsuario);
    idDep = (objJWTRecibido.idDep);
    idDiv = (objJWTRecibido.idDiv);
    perfil = String(objJWTRecibido.rol);
  }

  let {
    idUsuario,
    tipoUsuario,
    page,
    statususer,
    dobleEnlace,
    objeto,
  } = useFormulario<InfoSearch>(
    new InfoSearch("","",0, 0,0,0,5)
  );


  const [objAut, setObjAut] = useState<PersonaCompleta>(new PersonaCompleta(0,0,"","","","","","","",0,new Date(),new Date(),"","","","",0,0,0,0,"",1));

   

  const [arregloPersonas, setArregloPersonas] = useState<PersonaCompleta[]>([]);

  const [totalPaginas, setTotalPaginas] = useState<number>(0);
  const [paginaActual, setPaginaActual] = useState<number>(0);
  const [sizePage, setSizePage] = useState<number>(0);

  const handlePaginaFinal = () => {
    if (totalPaginas > 0 && paginaActual < totalPaginas - 1) {
      obtenerUsuarios(totalPaginas - 1);
    }
  };

  const handlePaginaInicial = () => {
    if (paginaActual !== 0)
    {
      obtenerUsuarios(0);

    }
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 0) {
      obtenerUsuarios(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    if (paginaActual < totalPaginas - 1) {
      obtenerUsuarios(paginaActual + 1);
    }
  };

  // Función para obtener AutorizaByPerson
  const obtenerUsuarios = async (pageSearch: number) => {
    let url = ApiBack.USUARIO_GETLISTUSERS;

    const objSearch = new InfoSearch(idUsuario, perfil, idDiv, idDep, tipoUsuario, pageSearch, statususer);

    const resultado = await ServicioPrivado.peticionPOST(url, objSearch);

    setArregloPersonas(resultado.content);

    setPaginaActual(resultado.pageable.pageNumber);
    setTotalPaginas(resultado.totalPages);
    setSizePage(resultado.size);

    setReadyUser(true);
    
    return resultado;
  };

  useEffect(() => {
    if (!readyUser) {
      obtenerUsuarios(0);
    }
  }, []);


  const verifyForm = async (fh: formHtml) => {
    fh.preventDefault();
    fh.stopPropagation(); // evita las acciones del formulario
    obtenerUsuarios(0);
  }


  const borrarUsuario = async (codigoUsuario: any) => {
    const urlDeshabilita = ApiBack.USUARIO_HABILITAR + "/" + codigoUsuario + "/0";
    const resultado = await ServicioPrivado.peticionGET(urlDeshabilita);
    console.log(resultado);
    if (typeof resultado.message === "undefined") {
      MesajeError("No se puede deshabilitar el usuario.");
    } else {
      MesajeSuccess( "Usuario deshabilitados exitosamente" );
    }
    obtenerUsuarios(paginaActual);
  };

  const deactivateUser = async (codigoUsuario: any) => {
    const urlDeshabilita = ApiBack.USUARIO_DESHABILITAR + "/" + codigoUsuario ; //+ "/0";
    const resultado = await ServicioPrivado.peticionGET(urlDeshabilita);
    obtenerUsuarios(paginaActual);
  }

  const activateUser = async (codigoUsuario: any) => {
    const urlDeshabilita = ApiBack.USUARIO_HABILITAR + "/" + codigoUsuario ;//+ "/1";
    const resultado = await ServicioPrivado.peticionGET(urlDeshabilita);
    obtenerUsuarios(paginaActual);
  }
  
  
  
 
  return (
    <main id="main" className="main">
      <div className="col-lg-11 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
          <Link to={"/dashboard/newUser/"} title="Nueva autorización">
            <i
              className="fa-regular fa-address-card fa-2xl"
              style={{ color: "#0a08bd" }}
              aria-label="Crear nueva autorización"
            ></i>
          </Link>
          <span className="fs-4">Listado de Usuarios Registrados</span>
        </header>

        <div className="d-flex align-items-center justify-content-center">
          <Form
            noValidate
            className="row g-3"
            validated={enProceso}
            onSubmit={verifyForm}
          >
            <div className="col-auto">
              <Form.Group controlId="idUsuario">
                <Form.Control
                  required
                  type="text"
                  name="idUsuario"
                  className="form-control"
                  value={idUsuario}
                  onChange={dobleEnlace}
                  placeholder="Documento"
                />
              </Form.Group>
            </div>
            <div className="col-auto">
              <Form.Group controlId="statususer">
                <Form.Select
                  required
                  name="statususer"
                  value={statususer}
                  onChange={dobleEnlace}
                  className="form-control"
                >
                  <option value={5}>Todos</option>
                  <option value={0}>Inactivos</option>
                  <option value={1}>Activos</option>
                  { perfil === "Contrainteligencia" && (
                    <option value={2}>Bloqueados</option>
                  )}
                  <option value={3}>Pendientes Activación</option>
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-auto">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  obtenerUsuarios(0);
                }}
              >
                Filtrar
              </Button>
            </div>
          </Form>
        </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "3%" }}> </th>
                    <th style={{ width: "9%" }}>Documento</th>
                    <th style={{ width: "13%" }}>Apellido</th>
                    <th style={{ width: "13%" }}>Nombre</th>
                    <th style={{ width: "9%" }}>Telefono</th>
                    <th style={{ width: "10%" }}>Correo</th>
                    <th style={{ width: "8%" }}>Fecha Ini</th>
                    <th style={{ width: "8%" }}>Fecha Fin</th>
                    <th style={{ width: "9%" }}>Rol</th>
                    <th style={{ width: "9%" }}>Direccion</th>
                    <th style={{ width: "9%" }}> </th>
                  </tr>
                </thead>
                <tbody>
                  {arregloPersonas.map((datoPersona, indice) => (
                    <tr key={indice + paginaActual * sizePage}>
                      <td>{indice + 1 + paginaActual * sizePage}</td>
                      <td>{datoPersona.documento}</td>
                      <td>{datoPersona.apellidos}</td>
                      <td>{datoPersona.nombres}</td>
                      <td>{datoPersona.telefono}</td>
                      <td>{datoPersona.correo}</td>
                      <td>{FormatFecha(datoPersona.fechaInicio)}</td>
                      <td>{FormatFecha(datoPersona.fechaTermino)} </td>
                      <td>{datoPersona.nombreRol}</td>
                      <td>
                        {datoPersona.edificio} {datoPersona.numeroEdificio}
                      </td>
                      <td>
                        {datoPersona.status === 1 && (
                          <>
                            <a
                              title="Bloquear acceso"
                              href="/#"
                              onClick={(e) => {
                                e.preventDefault();
                                deactivateUser(datoPersona.idPersona);
                              }}
                            >
                              <i
                                className="fa-solid fa-check"
                                style={{ color: "#009900" }}
                                aria-label="Desbloquear"
                              ></i>
                            </a>{" "}
                          </>
                        )}

                        {(datoPersona.status === 0 || datoPersona.status === 3) && (
                          <>
                            <a
                              title="Desbloquear acceso"
                              href="/#"
                              onClick={(e) => {
                                e.preventDefault();
                                activateUser(datoPersona.idPersona);
                              }}
                            >
                              <i
                                className="fa-solid fa-ban"
                                style={{ color: "#990000" }}
                                aria-label="Bloquear"
                              ></i>
                            </a>{" "}
                          </>
                        )}

                        <Link
                          to={"/dashboard/updateuser/" + datoPersona.documento}
                          title="Editar datos"
                        >
                          <i
                            className="fa-regular fa-pen-to-square"
                            style={{ color: "#006600" }}
                            aria-label="Editar datos"
                          ></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Modal para eliminar */}
              {/* *********************************************************************************/}
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Eliminar Autorización</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  ¿Realmente desea eleminar la autorizacion?
                  <br />
                  <strong>
                    {objAut.nombres} {objAut.apellidos} - desde{" "}
                    {objAut.fechaInicio.toString()} hasta{" "}
                    {objAut.fechaTermino.toString()}
                  </strong>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      setShow(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={(e) => {
                      borrarUsuario(objAut.idPersona);
                      setShow(false);
                    }}
                  >
                    Eliminar
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* *********************************************************************************/}
            </div>

            <nav aria-label="Page navigation">
              <ul className="pagination justify-content-end">
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePaginaInicial();
                    }}
                  >
                    <i className="fa-solid fa-backward-step"></i>
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePaginaAnterior();
                    }}
                  >
                    <i className="fa-solid fa-caret-left"></i>
                  </a>
                </li>
                <li className="page-item">{}</li>
                <a
                  className="page-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {paginaActual + 1} de {totalPaginas}
                </a>

                <li className="page-item">{}</li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePaginaSiguiente();
                    }}
                  >
                    <i className="fa-solid fa-caret-right"></i>
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePaginaFinal();
                    }}
                  >
                    <i className="fa-solid fa-forward-step"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="col-lg-11 mx-auto p-4 py-md-5"></div>
      </div>
    </main>
  );
};
