import { toast } from "react-toastify";

export const MesajeSuccess = (mensaje: string) => {
    toast.success(mensaje, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
