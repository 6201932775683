class SessionInit {
  public codigoUsuario: string;
  public password: string;

  constructor(cod: string, pas: string) {
    this.codigoUsuario = cod;
    this.password = pas;
  }
}

export default SessionInit;

