import { useState, useEffect } from "react";

import ApiBack from "../../../utils/domines/ApiBack";

import ServicioPrivado from "../../../services/ServicioPrivado";
import { Link, useParams } from "react-router-dom";
import Divisiones from "../../../models/Divisiones";
import { Button, Modal } from "react-bootstrap";
import { MesajeError } from "../../../utils/tools/MesajeError";
import { MesajeSuccess } from "../../../utils/tools/MesajeSuccess";

export const ListDivisiones = () => {

  const [show, setShow] = useState(false);
  const [readyDep, setReadyDep] = useState(false);
  const [readyDiv, setReadyDiv] = useState(false);
  const [nameDep, setNameDep] = useState("");
  
  const handleClose = () => setShow(false);
  const [enProceso, setEnProceso] = useState<boolean>(false);
  type formHtml = React.FormEvent<HTMLFormElement>;

  const [objDiv, setObjDiv] = useState<Divisiones>(new Divisiones(0,0, ""));


  const [arregloDivision, setArregloDivision] = useState<Divisiones[]>(
    []
  );

  let { idDepSearch } = useParams();

  const obtenerDependencia = async () => {
    let url = ApiBack.DEPENDENCIA_SEARCH + "/" + idDepSearch;
    const resultado = await ServicioPrivado.peticionGET(url);
    if (resultado.idDependencia)
    {
      setNameDep(resultado.nombre);
    }
    setReadyDep(true);

  };

  const obtenerDivisiones = async () => {
    let url = ApiBack.DIVISION_OBTENERTODASBYDEP + "/" + idDepSearch;
    const resultado = await ServicioPrivado.peticionGET(url);
    setArregloDivision(resultado);
    setReadyDiv(true);

    return resultado;
  };

  useEffect(() => {
    if(!readyDep)
    {
      obtenerDependencia();
    }
    if (!readyDiv) {
      obtenerDivisiones();
    }
  }, []);


  const borrarDivision = async (codigoDivision: any) => {
    const urlBorrar = ApiBack.DIVISION_ELIMINAR + "/" + codigoDivision;
    const resultado = await ServicioPrivado.peticionDELETE(urlBorrar);
    if ( resultado.status !== 200) {
      MesajeError("No se puede eliminar la división.");
    } else {
      MesajeSuccess( "La división ha sido eliminada" );
      obtenerDivisiones();
    }
  };


  return (
    <main id="main" className="main">
      <div className="col-lg-11 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
          <i
            className="fa-regular fa-address-card fa-2xl"
            style={{ color: "#0a08bd" }}
          ></i>
          <span className="fs-4">Listado de Divisiones de {nameDep}</span>
        </header>


        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}> </th>
                    <th style={{ width: "80%" }}>Division</th>
                    <th style={{ width: "10%" }}> </th>
                  </tr>
                </thead>
                <tbody>
                  {arregloDivision.map((datoDiv, indice) => (
                    <tr key={indice}>
                      <td>{indice + 1}</td>
                      <td>{datoDiv.nombre}</td>
                      <td>
                      <a
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setShow(true);
                          setObjDiv(datoDiv);
                        }}
                      >
                        <i
                          className="fa-solid fa-trash-can fa-sm"
                          style={{ color: "#990000" }}
                        ></i>
                      </a>{" "}

                      {" "}<Link
                          to={"/dashboard/updatediv/" + datoDiv.idDivision}
                          title="Editar nombre"
                        >
                          <i
                            className="fa-regular fa-pen-to-square"
                            style={{ color: "#006600" }}
                            aria-label="Editar nombre"
                          ></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>

                        {/* Modal para eliminar */}
            {/* *********************************************************************************/}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Eliminar División</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                ¿Realmente desea eleminar la división?
                <br />
                <strong>
                {objDiv.nombre} 
                </strong>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    setShow(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    borrarDivision(objDiv.idDivision);
                    setShow(false);
                  }}
                >
                  Eliminar
                </Button>
              </Modal.Footer>
            </Modal>
            {/* *********************************************************************************/}

          </div>
        </div>
        <div><a href={"/dashboard/newdiv/" + idDepSearch}>Agregar división...</a></div>

        <div className="col-lg-11 mx-auto p-4 py-md-5"></div>
      </div>
    </main>
  );
};
