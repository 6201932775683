


import { useState, useEffect, useContext } from "react";

import ApiBack from "../../../utils/domines/ApiBack";  

import ServicioPrivado from "../../../services/ServicioPrivado";
import { ContextoUsuario } from "../../../security/ContextoUsuario";
import { useFormulario } from "../../../utils/hooks/useFormulario";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import CrearPersonaAutorizacion from "../../../models/CrearPersonaAutorizacion";
import { FormatFecha } from '../../../utils/tools/ToolsDate';
import Roles from "../../../models/Roles";

//const miUsuario = useContext(ContextoUsuario);
//const idUsuario = miUsuario?.autenticado.idUsuario;
// /utilidades/dominios/ApiBack";

export const ShowAutorizacion = () => {

  let { idAutorizacion } = useParams();


  const miUsuario = useContext(ContextoUsuario);
  const idUsuario =  String(miUsuario?.autenticado.idUsuario);

  const [rolesListo, setRolesListo] = useState<boolean>(false);
  const [todoListo, setTodoListo] = useState<boolean>(false);
  const [arregloRoles, setArregloRoles] = useState<Roles[]>([]);
  const [rolAutorizado, setRolAutorizado] = useState<String>("");





  let {
    idAutorizaciones,
    idPersonaAutoriza,
    idAutorizado,
    documentoAutoriza,
    motivo,
    direccion,
    fotoCedula,
    fotoAutorizado,
    documentoAutorizado,
    nombreAutorizado,
    apellidosAutorizado,
    telefonoAutorizado,
    correoAutorizado,
    nombreAutoriza,
    apellidosAutoriza,
    telefonoAutoriza,
    correoAutoriza,
    placa,
    fechaInicio,
    fechaFinal,
    status,
    grado,
    idRol,
    dobleEnlace,
    objeto,
  } = useFormulario<CrearPersonaAutorizacion>(
    new CrearPersonaAutorizacion(0,0,0,idUsuario,"","","","","","","","","","","","","","",
    new Date(), new Date(),0,"",0,0,0,0,0)
  );

  const buscarPersona = async () => {

      const roles: Roles[] = await obtenerRoles();
      const url = ApiBack.OBTENER_PERSONAUTORIZADO + "/" + idAutorizacion;
      const idAdo = objeto.documentoAutorizado;

      const resultado = await ServicioPrivado.peticionGET(url);
      objeto.documentoAutorizado = idAdo;

      if (resultado.nombreAutorizado) {
        objeto.idAutorizaciones = resultado.idAutorizaciones;
        objeto.idAutorizado = resultado.idAutorizado;
        objeto.idPersonaAutoriza = resultado.idPersonaAutoriza;
        objeto.documentoAutoriza = resultado.documentoAutoriza;
        objeto.motivo = resultado.motivo; 
        objeto.direccion = resultado.direccion;
        objeto.fotoCedula = resultado.fotoCedula;
        objeto.fotoAutorizado = resultado.fotoAutorizado;
        objeto.documentoAutorizado = resultado.documentoAutorizado;
        objeto.nombreAutorizado = resultado.nombreAutorizado;
        objeto.apellidosAutorizado = resultado.apellidosAutorizado;
        objeto.telefonoAutorizado = resultado.telefonoAutorizado;
        objeto.correoAutorizado = resultado.correoAutorizado;
        objeto.nombreAutoriza = resultado.nombreAutoriza;
        objeto.apellidosAutoriza = resultado.apellidosAutoriza;
        objeto.telefonoAutoriza = resultado.telefonoAutoriza;
        objeto.correoAutoriza = resultado.correoAutoriza;
        objeto.fechaInicio = resultado.fechaInicio;
        objeto.fechaFinal = resultado.fechaFinal;
        objeto.placa = resultado.placa;
        objeto.grado = resultado.grado;
        objeto.autorizado = resultado.autorizado;
        objeto.idRol = resultado.idRol;

        const strRolAutorizado = roles.find(rol => rol.idRol === Number(resultado.idRol));
        if (strRolAutorizado)
          setRolAutorizado(strRolAutorizado?.nombreRol);

        
        setTodoListo(true);
      }
      else{
        setTodoListo(false);
      }
    
  };

  const obtenerRoles = async () => {
    let resultado;
    let url = ApiBack.ROLES_OBTENERTODOS;

    resultado = await ServicioPrivado.peticionGET(url);
    setArregloRoles(resultado);
    setRolesListo(true);
    return resultado;
  };

  useEffect(() => {
    if (!todoListo)
    {
      buscarPersona();
    }
  
  }, []);

  return (
    <div>
      <main id="main" className="main">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div>
                <h2 className="text-center">Datos del Visitante</h2>
              </div>
              <div id="imagenprevia" className="d-flex align-items-center justify-content-center">
                <img src={fotoAutorizado} alt="FOTO" id="imgPreView" height={155} width={"auto"}></img>
              </div>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "35%" }}> </th>
                    <th style={{ width: "65%" }}> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Numero de documento:</td>
                    <td>{documentoAutorizado}</td>
                  </tr>
                  <tr>
                    <td>Nombre:</td>
                    <td>
                      {nombreAutorizado} {apellidosAutorizado}
                    </td>
                  </tr>
                  <tr>
                    <td>Tipo autorización:</td>
                    <td>
                      {rolAutorizado} 
                    </td>
                  </tr>

                  
                  <tr>
                    <td>Motivo:</td>
                    <td>{motivo}</td>
                  </tr>
                  <tr>
                    <td>Dirección:</td>
                    <td>{direccion}</td>
                  </tr>
                  <tr>
                    <td>Telefono:</td>
                    <td>{telefonoAutorizado}</td>
                  </tr>
                  <tr>
                    <td>Correo:</td>
                    <td>{correoAutorizado}</td>
                  </tr>
                  <tr>
                    <td>Fecha Inicial:</td>
                    <td>{FormatFecha(fechaInicio)}</td>
                  </tr>
                  <tr>
                    <td>Fecha Final:</td>
                    <td>{FormatFecha(fechaFinal)}</td>
                  </tr>
                  <tr>
                    <td>Placa:</td>
                    <td>{placa}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div>
                <h2 className="text-center">
                  Datos de que Autoriza el ingreso
                </h2>
              </div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "35%" }}> </th>
                    <th style={{ width: "65%" }}> </th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Documento</td>
                    <td>{documentoAutoriza}</td>
                  </tr>
                  <tr>
                    <td>Grado</td>
                    <td>{grado}</td>
                  </tr>
                  <tr>
                    <td>Nombre:</td>
                    <td>
                      {nombreAutoriza} {apellidosAutoriza}
                    </td>
                  </tr>
                  <tr>
                    <td>Telefono:</td>
                    <td>{telefonoAutoriza}</td>
                  </tr>
                  <tr>
                    <td>Correo:</td>
                    <td>{correoAutoriza}</td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};