import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";

import ApiBack from "../../../utils/domines/ApiBack";

import ServicioPrivado from "../../../services/ServicioPrivado";
import { useFormulario } from "../../../utils/hooks/useFormulario";
import { Link, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import PersonaCompleta from "../../../models/PersonaCompleta";
import FilterAutoriza from "../../../models/FilterAutoriza";
import PersonaBloqueada from "../../../models/PersonaBloqueada";

export const ListDetailUserContra = () => {
  //const [fecIni, setFecIni] = useState <Date>(new Date);
  //const [fecFin, setFecFin] = useState <Date>(new Date);
  const [fecAct, setFecAct] = useState<Date>(new Date());
  const [show, setShow] = useState(false);
  const [readyUser, setReadyUser] = useState(false);
  const handleClose = () => setShow(false);
  const [enProceso, setEnProceso] = useState<boolean>(false);
  const [validDataUser, setValidDataUser] = useState<boolean>(false);
  const [validDataDetail, setValidDataDetail] = useState<boolean>(false);
  const [textToShow, setTextToShow] = useState<string>("");

  const [totalPaginas, setTotalPaginas] = useState<number>(0);
  const [paginaActual, setPaginaActual] = useState<number>(0);

  const handlePaginaFinal = () => {
    if (totalPaginas > 0 && paginaActual < totalPaginas - 1) {
      obtenerBloqueos(totalPaginas - 1);
    }
  };

  const handlePaginaInicial = () => {
    if (paginaActual !== 0)
    {
      obtenerBloqueos(0);

    }
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 0) {
      obtenerBloqueos(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    if (paginaActual < totalPaginas - 1) {
      obtenerBloqueos(paginaActual + 1);
    }
  };


  type formHtml = React.FormEvent<HTMLFormElement>;

  let { idUserSearch } = useParams();

  let { idPersona, tipoUsuario, dobleEnlace, objeto } =
    useFormulario<FilterAutoriza>(new FilterAutoriza("", 0, 0));

  const [objAut, setObjAut] = useState<PersonaCompleta>(
    new PersonaCompleta(
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      0,
      new Date(),
      new Date(),
      "",
      "",
      "",
      "",
      0,
      0,
      0,
      0,
      "",1
    )
  );

  const [arregloBloqueo, setArregloBloqueo] = useState<PersonaBloqueada[]>([]);

  // Función para obtener AutorizaByPerson
  const obtenerUsuario = async () => {
    let url = ApiBack.USUARIO_BUSCAR + "/" + idUserSearch;
    if (idPersona.length > 0) {
      url = ApiBack.USUARIO_OBTENERUNO + "/" + idPersona;
    }


    const resultado = await ServicioPrivado.peticionGET(url);

    if (resultado.idPersona) {
      idUserSearch = resultado.idPersona;
      setTextToShow(
        "Documento: " +
          resultado.documento +
          ", Nombre: " +
          resultado.nombres +
          " " +
          resultado.apellidos +
          ", Telefono: " +
          resultado.telefono +
          ", Correo: " +
          resultado.correo
      );
      obtenerBloqueos(0);

      setValidDataUser(true);
    } else {
      setValidDataUser(false);
    }
  };

  const obtenerBloqueos = async (page: number) => {
    let url = ApiBack.BLOQUEO_GETLISTBYUSER + "/" + idUserSearch + "/" + page;
    const resultado = await ServicioPrivado.peticionGET(url);

    setArregloBloqueo(resultado.content);

    setPaginaActual(resultado.pageable.pageNumber);
    setTotalPaginas(resultado.totalPages);

    setValidDataDetail(true);

    return resultado;
  };

  useEffect(() => {
    if (!validDataUser) {
      obtenerUsuario();
    }
    if (!validDataDetail) {
      obtenerBloqueos(0);
    }
  }, []);


  const verifyForm = async (fh: formHtml) => {
    fh.preventDefault();
    fh.stopPropagation();
    obtenerUsuario();
  };

  return (
    <main id="main" className="main">
      <div className="col-lg-11 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
          <i
            className="fa-regular fa-address-card fa-2xl"
            style={{ color: "#0a08bd" }}
          ></i>
          <span className="fs-4">Listado de Usuarios Registrados</span>
        </header>

        <div className="d-flex align-items-center justify-content-center">
          <Form
            noValidate
            className="row g-3"
            validated={enProceso}
            onSubmit={verifyForm}
          >
            <div className="col-auto">
              <Form.Group controlId="idPersona">
                <Form.Control
                  required
                  type="text"
                  name="idPersona"
                  className="form-control"
                  value={idPersona}
                  onChange={dobleEnlace}
                  placeholder="Documento"
                />
              </Form.Group>
            </div>
            <div className="col-auto">
              <Button onClick={obtenerUsuario}>Buscar</Button>
            </div>
          </Form>
        </div>

        <div className="pt-4 pb-2">
          <p className="card-title text-center pb-0 fs-4">{textToShow}</p>
        </div>

        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}> </th>
                    <th style={{ width: "10%" }}>Fecha</th>
                    <th style={{ width: "75%" }}>Motivo</th>
                    <th style={{ width: "10%" }}>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {arregloBloqueo.map((datoBloqueo, indice) => (
                    <tr key={indice}>
                      <td>{indice + 1}</td>
                      <td>{datoBloqueo.fechaBloqueo.toString()}</td>
                      <td>{datoBloqueo.motivoBloqueo}</td>
                      <td> {datoBloqueo.status === 0 ? 'Inactivo' : (datoBloqueo.status === 1 ? 'Activo' : 'Bloqueado')}</td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
              <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaInicial();
                        }}>Inicio</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaAnterior();
                        }}>Anterior</a>
                </li>
                <li className="page-item">{ }</li>
                <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                        }}>{paginaActual + 1} de {totalPaginas}</a>
                
                <li className="page-item">{ }</li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaSiguiente();
                        }}>Siguiente</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaFinal();
                        }}>Fin</a>
                </li>
              </ul>
            </nav>


          </div>
        </div>

        <div className="col-lg-11 mx-auto p-4 py-md-5"></div>
      </div>
    </main>
  );
};
