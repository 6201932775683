
class CreaPersonaBloqueada
{
  public idPersona  : number;
  public fechaBloqueo: Date;
  public motivoBloqueo: string;
  public status: number;

  constructor( idPersona  : number, fechaBloqueo: Date, motivoBloqueo: string, status: number) {
    this.idPersona = idPersona;
    this.fechaBloqueo = fechaBloqueo;
    this.motivoBloqueo = motivoBloqueo;
    this.status = status;
  }
}

export default CreaPersonaBloqueada;