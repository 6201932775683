
class CreaDivision
{

  public idDependencia: number;
  public nombre: string;

  constructor(id: number, name: string)
  {
    this.idDependencia = id;
    this.nombre = name;
  }
}

export default CreaDivision;