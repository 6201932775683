import { useState, useEffect, useContext } from "react";

import ApiBack from "../../../utils/domines/ApiBack";  

import ServicioPrivado from "../../../services/ServicioPrivado";
import {  Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PersonaAutorizacion from "../../../models/PersonaAutorizacion";
import { Button, Modal } from "react-bootstrap";
import { MesajeError } from "../../../utils/tools/MesajeError";
import { MesajeSuccess } from "../../../utils/tools/MesajeSuccess";
import { FormatFecha } from '../../../utils/tools/ToolsDate';
import { ContextoUsuario } from "../../../security/ContextoUsuario";
import { useFormulario } from "../../../utils/hooks/useFormulario";
import FilterAutoriza from "../../../models/FilterAutoriza";

//const miUsuario = useContext(ContextoUsuario);
//const idUsuario = miUsuario?.autenticado.idUsuario;
// /utilidades/dominios/ApiBack";

export const ListAutorizaByPerson = () => {


  //const [fecIni, setFecIni] = useState <Date>(new Date);
  //const [fecFin, setFecFin] = useState <Date>(new Date);
  type formHtml = React.FormEvent<HTMLFormElement>;

  const [fecAct, setFecAct] = useState <Date>(new Date);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const miUsuario = useContext(ContextoUsuario);
  const idUsuario =  String(miUsuario?.autenticado.idUsuario);

  const [objAut, setObjAut] = useState<PersonaAutorizacion>(new PersonaAutorizacion( 0,0,0,idUsuario,"","","","","","","","","","","","","","",new Date(), new Date(),0 ,"",0,0,0,0,0));

  const [arregloAutorizaByPerson, setArregloAutorizaByPerson] = useState<PersonaAutorizacion[]>([]);

  const [enProceso, setEnProceso] = useState<boolean>(false);

  const [totalPaginas, setTotalPaginas] = useState<number>(0);
  const [paginaActual, setPaginaActual] = useState<number>(0);

  const handlePaginaFinal = () => {
    if (totalPaginas > 0 && paginaActual < totalPaginas - 1) {
      obtenerAutorizaByPerson(totalPaginas - 1);
    }
  };

  const handlePaginaInicial = () => {
    if (paginaActual !== 0)
    {
      obtenerAutorizaByPerson(0);

    }
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 0) {
      obtenerAutorizaByPerson(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    if (paginaActual < totalPaginas - 1) {
      obtenerAutorizaByPerson(paginaActual + 1);
    }
  };

  let {
    idPersona,
    tipoUsuario,
    page,
    dobleEnlace,
    objeto,
  } = useFormulario<FilterAutoriza>(
    new FilterAutoriza("",0,0)
  );


  // Función para obtener AutorizaByPerson
  const obtenerAutorizaByPerson = async (page: number) => {
    let resultado;
    let url;
    if (idPersona.length > 0) {
        url = ApiBack.AUTORIZACION_OBTENER_LIST_BY_VISITANTE + "/" + idPersona + "/" + page;
    }
    else{
      url = ApiBack.AUTORIZACION_OBTENER_LIST_BY_AUTORIZA + "/" + idUsuario + "/" + page;
    }


    resultado = await ServicioPrivado.peticionGET(url);
    setArregloAutorizaByPerson(resultado.content);

    setPaginaActual(resultado.pageable.pageNumber);
    setTotalPaginas(resultado.totalPages);

    return resultado;
  };

  const verifyForm = async (fh: formHtml) => {
    fh.preventDefault();
    setEnProceso(true);
    obtenerAutorizaByPerson(paginaActual);
  }



  useEffect(() => {
    obtenerAutorizaByPerson(paginaActual);
  }, []);


  const borrarAutorizacion = async (codigoAutorizacion: any) => {
    const urlBorrar = ApiBack.AUTORIZACION_ELIMINAR + "/" + codigoAutorizacion;
    const resultado = await ServicioPrivado.peticionDELETE(urlBorrar);
    console.log(resultado);
    if (typeof resultado.message === "undefined") {
      MesajeError("No se puede eliminar la autorización.");
    } else {
      MesajeSuccess( "La autorización ha sido eliminada" );
    }
    obtenerAutorizaByPerson(paginaActual);
  };

  
  return (
      
    
    
      <div className="col-lg-12">

        
        <div className="card">
          <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                <th style={{ width: "3%" }} className="text-center"> </th>
                  <th style={{ width: "7%" }} className="text-center">Documento</th>
                  <th style={{ width: "26%" }} className="text-center">Apellido y Nombre</th>
                  <th style={{ width: "9%" }} className="text-center">Teléfono</th>
                  <th style={{ width: "10%" }} className="text-center">Correo</th>
                  <th style={{ width: "14%" }} className="text-center">Motivo</th>
                  <th style={{ width: "8%" }} className="text-center">Lugar</th>
                  <th style={{ width: "7%" }} className="text-center">Desde</th>
                  <th style={{ width: "7%" }} className="text-center">Hasta</th>
                  <th style={{ width: "8%" }}></th>
              </tr>
              </thead>
              <tbody>
                
              {arregloAutorizaByPerson.map((personaAutorizacion, indice) => (
                  <tr key={indice}>
                    <td>
                      {personaAutorizacion.autorizado === 1 && (<>
                      <i className="fa-solid fa-thumbs-up"
                      style={{ color: "#006600" }}></i>
                      </>)}
                      {personaAutorizacion.autorizado !== 1 && (<>
                      <i className="fa-solid fa-thumbs-down"
                      style={{ color: "#660000" }}></i>
                      </>)}
                    
                    </td>
                    <td>{personaAutorizacion.documentoAutorizado}</td>
                    <td>{personaAutorizacion.apellidosAutorizado}{" "}{personaAutorizacion.nombreAutorizado}</td>
                    <td>{personaAutorizacion.telefonoAutorizado}</td>
                    <td>{personaAutorizacion.correoAutorizado}</td>
                    <td>{personaAutorizacion.motivo}</td>
                    <td>{personaAutorizacion.direccion}</td>
                    <td>{FormatFecha(personaAutorizacion.fechaInicio)}</td>
                    <td>{FormatFecha(personaAutorizacion.fechaFinal)} </td>
                    <td>
                      <Link to={"/dashboard/detailautoriza/" + personaAutorizacion.idAutorizaciones}>
                        <i className="fa-solid fa-magnifying-glass fa-sm"></i>
                      </Link>{" "}
                    { fecAct <= new Date(personaAutorizacion.fechaInicio) && ( <>
                    
                      <a
                        href="/#"
                        onClick={(e) => {
                          e.preventDefault();
                          setShow(true);
                          setObjAut(personaAutorizacion);
                        }}
                      >
                        <i
                          className="fa-solid fa-trash-can fa-sm"
                          style={{ color: "#990000" }}
                        ></i>
                      </a>{" "}
                      <Link to={"/dashboard/updateautoriza/" + personaAutorizacion.idAutorizaciones}>
                        <i
                          className="fa-regular fa-pen-to-square"
                          style={{ color: "#006600" }}
                        ></i>
                        </Link>

                    </>) }


                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <nav aria-label="Page navigation">
              <ul className="pagination justify-content-end">
              <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaInicial();
                        }}><i className="fa-solid fa-backward-step"></i></a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaAnterior();
                        }}><i className="fa-solid fa-caret-left"></i></a>
                </li>
                <li className="page-item">{ }</li>
                <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                        }}>{paginaActual + 1} de {totalPaginas}</a>
                
                <li className="page-item">{ }</li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaSiguiente();
                        }}><i className="fa-solid fa-caret-right"></i></a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaFinal();
                        }}><i className="fa-solid fa-forward-step"></i></a>
                </li>
              </ul>
            </nav>

            {/* Modal para eliminar */}
            {/* *********************************************************************************/}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Eliminar Autorización</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                ¿Realmente desea eleminar la autorizacion?
                <br />
                <strong>
                {objAut.nombreAutorizado}  {objAut.apellidosAutorizado} - desde {objAut.fechaInicio.toString()} hasta {objAut.fechaFinal.toString()}
                </strong>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    setShow(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    borrarAutorizacion(objAut.idAutorizaciones);
                    setShow(false);
                  }}
                >
                  Eliminar
                </Button>
              </Modal.Footer>
            </Modal>
            {/* *********************************************************************************/}

          </div>
        </div>
      </div>
  );
};