import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { ContextoUsuario } from "../../../security/ContextoUsuario";
import { useFormulario } from "../../../utils/hooks/useFormulario";

import * as cifrado from "js-sha512";
import { propUsuario } from "../../../models/MisInterfaces";

import logoEnap from "../../../../assets/image/logo_enap.png";
import Usuario from "../../../models/Usuario";
import ApiBack from "../../../utils/domines/ApiBack";
import ServicioPrivado from "../../../services/ServicioPrivado";

// Para los mensajes, no olvidar !!!
// *******************************************************************
export const EditPassword = () => {
  // Variables
  // *******************************************************************
  const navigate = useNavigate();
  type formHtml = React.FormEvent<HTMLFormElement>;
  const { actualizar } = useContext(ContextoUsuario) as propUsuario;
  const [enProceso, setEnProceso] = useState<boolean>(false);
  const [readyUser, setReadyUser] = useState(false);
  const [validData, setValidData] = useState<boolean>(false);

  const passActRef = useRef<HTMLInputElement>(null);
  const passNewRef = useRef<HTMLInputElement>(null);
  const passRepRef = useRef<HTMLInputElement>(null);

  const miUsuario = useContext(ContextoUsuario);
  const idUsuario =  String(miUsuario?.autenticado.idUsuario);



  // Manejo de formulario con Hook personalizado (sin librerias externas)
  // *******************************************************************
  let {
    idPersona,
    tipoDocumento,
    nombres,
    apellidos,
    documento,
    password,
    foto,
    status,
    telefono,
    correo,
    grado,
    dobleEnlace,
    objeto,
  } = useFormulario<Usuario>(
    new Usuario(0,0,"","","","","","","","",0)
  );
  


  // Función flecha para resetear variables y limpiar cajas del formulario
  // *******************************************************************
  const limpiarCajas = (formulario: HTMLFormElement) => {
    formulario.reset();

    //formulario.tipoDocumento.value = "1";

    formulario.classList.remove("was-validated");
  };

  // Función flecha para presentar mensaje de error estilo toastify
  // *******************************************************************
  const mensajeError = (mensaje: string) => {
    toast.error(mensaje, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const verifyForm = async (fh: formHtml) => {
    /**
     * previene acciones por defecto de los formularios html
     */
    fh.preventDefault();
    setEnProceso(true);
    /**
     * se captura la información del formulario actual
     */
    const formActual = fh.currentTarget;
    formActual.classList.add("was-validated");

    let passActValue: string = ""; 
    let passNewValue: string = ""; 
    let passRepValue: string = ""; 
    let claveCifrada = "";
    if (passActRef.current?.value)
    {
      passActValue = passActRef.current?.value;
      claveCifrada = cifrado.sha512(passActValue);
    }
    if (passNewRef.current?.value)
      passNewValue = passNewRef.current?.value;
    if (passRepRef.current?.value)
      passRepValue = passRepRef.current?.value;

    if (formActual.checkValidity() === false) {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
    }else if (claveCifrada !== objeto.password)
    {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
      mensajeError("La contraseña actual no corresponde al valor ingresado");
    } else if (passNewValue !== passRepValue)
    {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
      mensajeError("La contraseña nueva y su repetición no coinciden");

    } else {
      //console.log("Conexión al backend");
      //const fromBackend = await ServicioPublico.createUser(objeto);
      //console.log(fromBackend);

      // Código para crear el usuario consumiendo servicio del back y usando sha512
      // *******************************************************************

      const claveCifrada2 = cifrado.sha512(passNewValue);
      objeto.password = claveCifrada2;

      const url = ApiBack.USUARIO_UPDATE;
      const resultado = await ServicioPrivado.peticionPUT(url, objeto); ;//await ServicioPublico.crearUsuario(objeto);
      console.log("despues de enviar " + resultado);
      if (resultado) {

//        navigate("/login");
        mensajeExito("Contraseña actualizada exitosamente.");
        setEnProceso(false);
        obtenerUsuario();
        limpiarCajas(formActual);
      } else {
        //limpiarCajas(formActual);
        mensajeError("Informacion incompleta o erronea, no fue posible actualizar la clave");
        //MensajeToastify("Error", "No se puede crear el usuario. Correo o perfil incorrectos.", 7000);
      }
    }
  };
  
  const mensajeExito = (mensaje: string) => {
    toast.success(mensaje, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const obtenerUsuario = async () => {

    const url = ApiBack.USUARIO_OBTENERUNO + "/" + idUsuario;

    const resultado = await ServicioPrivado.peticionGET(url);

    if (resultado.nombres) {
      objeto.idPersona = resultado.idPersona;
      objeto.tipoDocumento = resultado.tipoDocumento;
      objeto.documento = resultado.documento;
      objeto.nombres = resultado.nombres;
      objeto.apellidos = resultado.apellidos;
      objeto.telefono = resultado.telefono;
      objeto.correo = resultado.correo;
      objeto.foto = resultado.foto;
      objeto.password = resultado.password;
      objeto.grado = resultado.grado;
      objeto.status = resultado.status;
      setValidData(true);
    } else {
      
      console.log("No llega data");
      setValidData(false);
    }
  
};


  
  useEffect(() => {
    if (!readyUser) {
      obtenerUsuario();
    }
  }, []);


  return (
    <div>
      <main>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src={logoEnap} alt="" />
                      <span className="d-none d-lg-block">
                        Sistema de Ingreso ENAP
                      </span>
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Cambio de Contraseña de Ingreso
                        </h5>
                        
                      </div>

                      <Form
                        noValidate
                        className="row g-3"
                        validated={enProceso}
                        onSubmit={verifyForm}
                      >
                        

                        <div className="col-12">
                          <Form.Group controlId="passAct">
                            <Form.Label>Contraseña Actual</Form.Label>
                            <Form.Control
                              required
                              type="password"
                              name="passAct"
                              className="form-control"
                              minLength={4}
                              ref={passActRef}
                          />
                            <Form.Control.Feedback type="invalid">
                              La contraseña no coincide
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="passNew">
                            <Form.Label>Contraseña Nueva</Form.Label>
                            <Form.Control
                              required
                              type="password"
                              name="passNew"
                              className="form-control"
                              minLength={4}
                              ref={passNewRef}
                          />
                            <Form.Control.Feedback type="invalid">
                              Mínimo 4 caracteres
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="passAct">
                            <Form.Label>Repita la Contraseña</Form.Label>
                            <Form.Control
                              required
                              type="password"
                              name="passAct"
                              className="form-control"
                              minLength={4}
                              ref={passRepRef}
                          />
                            <Form.Control.Feedback type="invalid">
                              Mínimo 4 caracteres o las contraseñas no coiciden
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Actualizar
                          </button>
                        </div>
                      </Form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};
