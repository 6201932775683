import Form from "react-bootstrap/Form";
import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import jwtDecode from "jwt-decode";
import * as cifrado from "js-sha512";

import MiSesion from "../../models/MiSesion";
import SessionInit from "../../models/SessionInit";
import { propUsuario } from "../../models/MisInterfaces";
import logoEnap from "../../../assets/image/logo_enap.png";
import ServicioPublico from "../../services/ServicioPublico";
import { ContextoUsuario } from "../../security/ContextoUsuario";
import { useFormulario } from "../../utils/hooks/useFormulario";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

export const InicioSesion = () => {
  // Definición de variables
  // *******************************************************************
  const navigate = useNavigate();
  type formaHtml = React.FormEvent<HTMLFormElement>;
  const [enProceso, setEnProceso] = useState<boolean>(false);
  const { actualizar } = useContext(ContextoUsuario) as propUsuario;

  // Formulario con hooks
  // *******************************************************************
  let { codigoUsuario, password, dobleEnlace, objeto } =
    useFormulario<SessionInit>(new SessionInit("", ""));

  // Función flecha para limpiar cajas
  const limpiarCajas = (formulario: HTMLFormElement) => {
    formulario.reset();

    objeto.codigoUsuario = "";
    objeto.password = "";

    formulario.codigoUsuario.value = "";
    formulario.password.value = "";

    formulario.classList.remove("was-validated");
  };

  // Función flecha para presentar mensaje de error estilo toastify
  // *******************************************************************
  const mensajeError = () => {
    toast.error("No se puede iniciar sesión. Credenciales incorrectas", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  // Iniciar sesión
  // *******************************************************************
  const enviarFormulario = async (fh: formaHtml) => {
    fh.preventDefault();
    setEnProceso(true);
    const formulario = fh.currentTarget;
    formulario.classList.add("was-validated");
    if (formulario.checkValidity() === false) {
      fh.preventDefault();
      fh.stopPropagation();
    } else {
      const claveCifrada = cifrado.sha512(objeto.password);
      objeto.password = claveCifrada;
      const resultado = await ServicioPublico.iniciarSesion(objeto);

      if (resultado.token) {
        const objJWTRecibido: any = jwtDecode(resultado.token);

        const usuarioCargado = new MiSesion(
          objJWTRecibido.idUsuario,
          objJWTRecibido.nombre,
          objJWTRecibido.mail,
          objJWTRecibido.rol,
          objJWTRecibido.idDiv,
          objJWTRecibido.idDep
        );
        actualizar(usuarioCargado);

        localStorage.setItem("tokenSingenap", resultado.token);
        navigate("/dashboard");
        setEnProceso(false);
      } else {
        limpiarCajas(formulario);
        mensajeError();
      }
    }
  };

  return (
    <div>
      <main>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src={logoEnap} alt="" />
                      <span className="d-none d-lg-block">ENAP</span>
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Inicio de sesión
                        </h5>
                        <p className="text-center small">
                          Credenciales requeridas
                        </p>
                      </div>

                      <Form
                        noValidate
                        validated={enProceso}
                        onSubmit={enviarFormulario}
                        className="row g-3"
                      >
                        <div className="col-12">
                          <Form.Group controlId="codigoUsuario">
                            <Form.Label>Documento de Identidad</Form.Label>
                            <div className="input-group has-validation">
                              <Form.Control
                                required
                                type="text"
                                name="codigoUsuario"
                                className="form-control"
                                value={codigoUsuario}
                                onChange={dobleEnlace}
                              />
                              <Form.Control.Feedback type="invalid">
                                El numero de documento es obligatorio
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="password">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                              required
                              type="password"
                              name="password"
                              className="form-control"
                              minLength={4}
                              value={password}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              Mínimo 4 caracteres
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Iniciar sesión
                          </button>
                        </div>
                        <div className="col-12">
                          <p className="small mb-0">
                            ¿No tienes cuenta?{" "}
                            <Link to="/register">Clic aquí para crear una</Link>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};
