import ApiBack from "../utils/domines/ApiBack";
import { ExtracUrl } from "../utils/domines/ExtracUrl";

class ServicioPublico {
  // Servicio para crear usuarios
  // *******************************************************************
  public static async crearUsuario(objeto: any) {
    const datosEnviar = {
      method: "POST",
      body: JSON.stringify(objeto),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const url = ApiBack.SERVICE + ExtracUrl() + ApiBack.URL +  ApiBack.USUARIO_CREAR;
    const respuesta = fetch(url, datosEnviar)
      .then((respuesta) => respuesta.json())
      .then((datos) => {
        console.log("DAtos: " + datos)

        return datos;
      })
      .catch((miError) => {
        console.log("miError: " + miError)
        return miError;
      });
    return respuesta;
  }

  // Servicio para iniciar sesión
  // *******************************************************************
  public static async iniciarSesion(objeto: any) {
    const datosEnviar = {
      method: "POST",
      body: JSON.stringify(objeto),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };

    const url = ApiBack.SERVICE + ExtracUrl() + ApiBack.URL +  ApiBack.INICIAR_SESION;
    console.log("Url a consultar: " + url);
    // console.log("Datos a enviar: " + JSON.stringify(datosEnviar));
    const respuesta = fetch(url, datosEnviar)
      .then((respuesta) => respuesta.json())
      .then((datos) => {
        return datos;
      })
      .catch((miError) => {
        return miError;
      });
    return respuesta;
  }
}

export default ServicioPublico;
