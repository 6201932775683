class FilterAutoriza
{
  public idPersona: string;
  public tipoUsuario: number;
  public page: number;

  constructor(id: string, tusu: number, page: number)
  {
    this.idPersona = id;
    this.tipoUsuario = tusu;
    this.page = page;
  }
}

export default FilterAutoriza;