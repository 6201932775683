
class CrearDependencia
{

  public nombre: string;

  constructor( name: string)
  {
    this.nombre = name;
  }
}

export default CrearDependencia;