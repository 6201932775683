import Usuario from "./Usuario";

class Autorizacion
{
  public idAutorizaciones: number
  public idPersonaAutoriza: number;
  public idAutorizado:  number;
  public fechaInicio: Date;
  public fechaFinal: Date;
  public motivo: string;
  public direccion: string;
  public fotoCedula: string;
  public fotoAutorizado: string;
  public placa: string;
  public autorizado: number;

  constructor(idAut: number, idUsu: number, idVis: number, fecIni: Date, fecFin: Date, mot: string, 
    dir: string, fCed: string, fAut: string, placa: string, autorizado: number)
  {
    this.idAutorizaciones = idAut;
    this.idPersonaAutoriza = idUsu;
    this.idAutorizado = idVis;
    this.fechaInicio = fecIni;
    this.fechaFinal = fecFin;
    this.motivo = mot;
    this.direccion = dir;
    this.fotoCedula = fCed;
    this.fotoAutorizado = fAut;
    this.placa = placa;
    this.autorizado = autorizado;
  }
}

export default Autorizacion;