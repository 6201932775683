const opcionesAdmin = [
  { nombre: "Inicio", icono: "bi bi-house", ruta: "/dashboard", hijos: [], },
  { nombre: "Acerca de", icono: "bi bi-grid", ruta: "/dashboard/about", hijos: [], },
  { nombre: "Permisos", icono: "bi bi-clipboard-data", ruta: "", hijos: [
      { nombre: "Mis permisos", icono: "bi bi-circle", ruta: "/dashboard/listPermisos", },
      { nombre: "Nuevo", icono: "bi bi-circle", ruta: "/dashboard/newPermiso" },
      { nombre: "Administración", icono: "bi bi-circle", ruta: "/dashboard/listautoriza", },
    ]
  },
  { nombre: "Usuarios", icono: "bi bi-person-lines-fill", ruta: "", hijos: [
    { nombre: "Administración", icono: "bi bi-circle", ruta: "/dashboard/listUser", },
    { nombre: "Nuevo", icono: "bi bi-circle", ruta: "/dashboard/newUser" },
    { nombre: "Contraseña", icono: "bi bi-circle", ruta: "/dashboard/editpassuser" },
    ]
  },
  { nombre: "Unidades", icono: "bi bi-person-lines-fill", ruta: "", hijos: [
    { nombre: "Administración", icono: "bi bi-circle", ruta: "/dashboard/listdep", },
    { nombre: "Nueva", icono: "bi bi-circle", ruta: "/dashboard/newdep" },
    ]
  },

];

const opcionesGuardia = [
  { nombre: "Inicio", icono: "bi bi-house", ruta: "/dashboard", hijos: [], },
  { nombre: "Acerca de", icono: "bi bi-grid", ruta: "/dashboard/about", hijos: [], },
  { nombre: "Permisos", icono: "bi bi-clipboard-data", ruta: "", hijos: [
      { nombre: "Listar", icono: "bi bi-circle", ruta: "/dashboard/listcap", },
    ]
  },
  { nombre: "Usuario", icono: "bi bi-person-lines-fill", ruta: "", hijos: [
    { nombre: "Datos", icono: "bi bi-circle", ruta: "/dashboard/", },
    { nombre: "Editar", icono: "bi bi-circle", ruta: "/dashboard/editdataUser" },
    { nombre: "Clave", icono: "bi bi-circle", ruta: "/dashboard/editpassword" },
    ]
  }
];

const opcionesContra = [
  { nombre: "Inicio", icono: "bi bi-house", ruta: "/dashboard", hijos: [], },
  { nombre: "Acerca de", icono: "bi bi-grid", ruta: "/dashboard/about", hijos: [], },
  { nombre: "Permisos", icono: "bi bi-clipboard-data", ruta: "", hijos: [
      { nombre: "Mis permisos", icono: "bi bi-circle", ruta: "/dashboard/listPermisos", },
      { nombre: "Nuevo", icono: "bi bi-circle", ruta: "/dashboard/newPermiso" },
      { nombre: "Administración", icono: "bi bi-circle", ruta: "/dashboard/listautoriza", },
    ]
  },
  { nombre: "Usuarios", icono: "bi bi-person-lines-fill", ruta: "", hijos: [
    { nombre: "Bloqueos", icono: "bi bi-circle", ruta: "/dashboard/listUserContra", },
    { nombre: "Datos", icono: "bi bi-circle", ruta: "/dashboard/", },
    { nombre: "Editar", icono: "bi bi-circle", ruta: "/dashboard/editdataUser" },
    { nombre: "Clave", icono: "bi bi-circle", ruta: "/dashboard/editpassword" },
  
    ]
  }
];


// *********************************************************************************


const opcionesInvitado = [
  { nombre: "Inicio", icono: "bi bi-house", ruta: "/dashboard", hijos: [], },
  { nombre: "Acerca de", icono: "bi bi-grid", ruta: "/dashboard/about", hijos: [], },
  { nombre: "Perfil", icono: "bi bi-person-lines-fill", ruta: "", hijos: [
    { nombre: "Datos", icono: "bi bi-circle", ruta: "/dashboard/", },
    { nombre: "Editar", icono: "bi bi-circle", ruta: "/dashboard/editdataUser" },
    ]
  }

];

const opcionesAsignatario = [
  { nombre: "Inicio", icono: "bi bi-house", ruta: "/dashboard", hijos: [], },
  { nombre: "Acerca de", icono: "bi bi-grid", ruta: "/dashboard/about", hijos: [], },
  { nombre: "Permisos", icono: "bi bi-clipboard-data", ruta: "", hijos: [
      { nombre: "Listar", icono: "bi bi-circle", ruta: "/dashboard/listPermisos", },
      { nombre: "Nuevo", icono: "bi bi-circle", ruta: "/dashboard/newPermiso" },
    ]
  },
  { nombre: "Perfil", icono: "bi bi-person-lines-fill", ruta: "", hijos: [
    { nombre: "Datos", icono: "bi bi-circle", ruta: "/dashboard/", },
    { nombre: "Editar", icono: "bi bi-circle", ruta: "/dashboard/editdataUser" },
    { nombre: "Clave", icono: "bi bi-circle", ruta: "/dashboard/editpassword" },
    ]
  }
];

const opcionesFuncionario = [
  { nombre: "Inicio", icono: "bi bi-house", ruta: "/dashboard", hijos: [], },
  { nombre: "Acerca de", icono: "bi bi-grid", ruta: "/dashboard/about", hijos: [], },
  { nombre: "Permisos", icono: "bi bi-clipboard-data", ruta: "", hijos: [
      { nombre: "Listar", icono: "bi bi-circle", ruta: "/dashboard/listPermisos", },
      { nombre: "Nuevo", icono: "bi bi-circle", ruta: "/dashboard/newPermiso" },
    ]
  },
{ nombre: "Perfil", icono: "bi bi-person-lines-fill", ruta: "", hijos: [
  { nombre: "Datos", icono: "bi bi-circle", ruta: "/dashboard/", },
  { nombre: "Editar", icono: "bi bi-circle", ruta: "/dashboard/editdataUser" },
  { nombre: "Clave", icono: "bi bi-circle", ruta: "/dashboard/editpassword" },
]
}
];



export { opcionesAdmin, opcionesInvitado, opcionesAsignatario, opcionesFuncionario, opcionesGuardia, opcionesContra };
