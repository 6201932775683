class CrearPersonaAutorizacion {
  public idAutorizaciones: number;
  public idPersonaAutoriza: number;
  public idAutorizado: number;
  public documentoAutoriza: string;
  public motivo: string;
  public direccion: string;
  public fotoCedula: string;
  public fotoAutorizado: string;
  public documentoAutorizado: string;
  public nombreAutorizado: string;
  public apellidosAutorizado: string;
  public telefonoAutorizado: string;
  public correoAutorizado: string;
  public nombreAutoriza: string;
  public apellidosAutoriza: string;
  public telefonoAutoriza: string;
  public correoAutoriza: string;
  public placa: string;
  public fechaInicio: Date;
  public fechaFinal: Date;
  public status: number;
  public grado: string;
  public idRol: number;
  public autorizado: number;
  public idDivision: number;
  public idDependencia: number;
  public idPersonaRol: number;

  constructor(
    idAutorizaciones: number,
    idPersonaAutoriza: number,
    idAutorizado: number,
    documentoAutoriza: string,
    motivo: string,
    direccion: string,
    fotoCedula: string,
    fotoAutorizado: string,
    documentoAutorizado: string,
    nombreAutorizado: string,
    apellidosAutorizado: string,
    telefonoAutorizado: string,
    correoAutorizado: string,
    nombreAutoriza: string,
    apellidosAutoriza: string,
    telefonoAutoriza: string,
    correoAutoriza: string,
    placa: string,
    fechaInicio: Date,
    fechaFinal: Date,
    status: number,
    grado: string,
    idRol: number,
    autorizado: number,
    idDivision: number,
    idDependencia: number,
    idPersonaRol: number
  ) {
    this.idDependencia = idDependencia;
    this.idDivision = idDivision;
    this.idAutorizaciones = idAutorizaciones;
    this.idPersonaAutoriza = idPersonaAutoriza;
    this.idAutorizado = idAutorizado;
    this.documentoAutoriza = documentoAutoriza;
    this.motivo = motivo;
    this.direccion = direccion;
    this.fotoCedula = fotoCedula;
    this.fotoAutorizado = fotoAutorizado;
    this.documentoAutorizado = documentoAutorizado;
    this.nombreAutorizado = nombreAutorizado;
    this.apellidosAutorizado = apellidosAutorizado;
    this.telefonoAutorizado = telefonoAutorizado;
    this.correoAutorizado = correoAutorizado;
    this.nombreAutoriza = nombreAutoriza;
    this.apellidosAutoriza = apellidosAutoriza;
    this.telefonoAutoriza = telefonoAutoriza;
    this.correoAutoriza = correoAutoriza;
    this.placa = placa;
    this.fechaInicio = fechaInicio;
    this.fechaFinal = fechaFinal;
    this.status = status;
    this.grado = grado;
    this.idRol = idRol;
    this.autorizado = autorizado;
    this.idPersonaRol = idPersonaRol;
  }
}

export default CrearPersonaAutorizacion;
