class UsuarioLogin
{
  public idPersona: number;
  public documento: string;
  public nombres: string;
  public apellidos: string;
  public password: string;
  public correo: string;
  public foto: string;
  public fechaInicio: Date;
  public fechaTermino: Date;
  public nombreRol: string;
  public grado: string;
  

  constructor(id: number, doc: string, nom: string, ape: string, 
    pass: string, mail: string, fot: string, fIni: Date, fFin: Date, rol: string, grado: string)
  {
    this.idPersona = id;
    this.apellidos = ape;
    this.correo = mail;
    this.nombres = nom;
    this.fechaInicio = fIni;
    this.fechaTermino = fFin;
    this.documento = doc;
    this.foto = fot;
    this.nombreRol = rol;
    this.password = pass;
    this.grado = grado;
  }
}

export default UsuarioLogin;