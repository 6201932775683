const ApiBack = {
  //URL: "http://192.168.1.89:8080/singenap",
  SERVICE: "https://",
  URL: ":8443/singenap",
  USUARIO_GETLIST : "/personac/todas",
  USUARIO_GETLISTUSERS : "/personac/allusers",
  USUARIO_GETLISTBYDOC : "/personac/todasbyDoc",
  USUARIO_CREAR : "/persona/grabar",
  USUARIO_OBTENER : "/persona/reqDataPerson",
  USUARIO_OBTENERUNO : "/personac/buscarDoc",
  USUARIO_UPDATE : "/persona/actualizar",
  USUARIO_BUSCAR: "/persona/buscar",
  USUARIO_GETLISTCONTRA : "/personac/todascontra",
  USUARIO_GETLISTBYDOCCONTRA : "/personac/todasbyDocContra",
  BLOQUEO_AGREGAR: "/bloqueada/grabar",
  BLOQUEO_GETLISTBYUSER: "/bloqueada/todasbyiduser",
  
  DIVISION_OBTENERTODASBYDEP : "/divisiones/buscarbydep",
  DIVISION_ELIMINAR : "/divisiones/eliminar",
  DIVISION_UPDATE : "/divisiones/actualizar",
  DIVISION_CREATE : "/divisiones/grabar",
  DIVISION_SEARCH : "/divisiones/buscar",

  DEPENDENCIA_OBTENERTODAS : "/dependencia/todas",
  DEPENDENCIA_CREATE : "/dependencia/grabar",
  DEPENDENCIA_UPDATE : "/dependencia/actualizar",
  DEPENDENCIA_SEARCH : "/dependencia/buscar",
 
  ROLES_OBTENERTODOS : "/roles/todos",
  ROLES_OBTENERUSUARIOS : "/roles/usuarios",
  ROLES_OBTENERINVITADOS : "/roles/invitados",

  PEERSONAROL_UPDATE : "/rolpersona/actualizar",
  
  USUARIO_ELIMINAR : "/persona/eliminar",
  USUARIO_DESHABILITAR : "/persona/deactivate",
  USUARIO_HABILITAR : "/persona/activate",
  USUARIO_UPDATE_DIR : "/personac/actualizarPerDir",
  USUARIO_UPDATE_ADM : "/personac/actualizarPerAdm",
  USUARIO_CREATE_ADM : "/personac/createPerAdm",
  INICIAR_SESION : "/login/init",
  CREAR_AUTORIZACION: "/autorizacion/creaautorizacion",
  OBTENER_AUTORIZADO: "/autorizacion/buscarbyatorizado",
  AUTORIZACION_OBTENER_LIST_BY_AUTORIZA : "/autorizacion/buscarbyatoriza",
  AUTORIZACION_OBTENER_LIST_BY_VISITANTE : "/autorizacion/buscarbyvisitante",
  AUTORIZACION_OBTENER_LIST_BY_AUTORIZACAP : "/autorizacion/buscarbyatorizacap",
  AUTORIZACION_OBTENER_LIST_BY_VISITANTECAP : "/autorizacion/buscarbyvisitantecap",
  AUTORIZACION_OBTENER_LIST : "/autorizacion/listatorizado",
  AUTORIZACION_TODASCAP : "/autorizacion/allautorizacap",
  AUTORIZACION_TODAS : "/autorizacion/allautoriza",
  AUTORIZACION_ELIMINAR : "/autorizacion/eliminar",
  OBTENER_PERSONAUTORIZADO : "/autorizacion/buscarpa",
  UPDATE_AUTORIZACION : "/autorizacion/actualizar",
};

export default ApiBack;