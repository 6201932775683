import { useNavigate } from "react-router-dom";
import { MouseEvent, useContext } from "react";

import perfilUsu from "../../assets/image/perfilUsuario.png";
import { ContextoUsuario } from "../security/ContextoUsuario";
import { OcultarMenu } from "../utils/functions/OcultarMenu";

export const MenuSuperior = () => {
  const navegacion = useNavigate();
  const miUsuario = useContext(ContextoUsuario);
  const correoUsuario = miUsuario?.autenticado.mail;
  const nombreUsuario = miUsuario?.autenticado.nombre;
  const miPerfil = miUsuario?.autenticado.perfil;

  const cerrarSesion = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    localStorage.removeItem("tokenSingenap");
    
    navegacion("/login");
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center">
          <img src="assets/img/logo.png" alt="" />
          <span className="d-none d-lg-block">SINGENAP</span>
        </a>
        <i className="bi bi-list toggle-sidebar-btn" onClick={OcultarMenu}></i>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          
          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="/#"
              data-bs-toggle="dropdown"
            >
              <img src={perfilUsu} alt="Profile" className="rounded-circle" />
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {correoUsuario}
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>{nombreUsuario}</h6>
                <span>{miPerfil}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/dashboard"
                >
                  <i className="bi bi-person"></i>
                  <span>Mi perfil</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/dashboard/editdatauser"
                >
                  <i className="bi bi-gear"></i>
                  <span>Editar</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/dashboard/editpassword"
                >
                  <i className="bi bi-key"></i>
                  <span>Cambiar clave</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <a
                  href="/#"
                  className="dropdown-item d-flex align-items-center"
                  onClick={cerrarSesion}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  Cerrar sesión
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};
