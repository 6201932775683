import Form from "react-bootstrap/Form";
import { useState, useEffect, useContext } from "react";

import ApiBack from "../../../utils/domines/ApiBack";  

import ServicioPrivado from "../../../services/ServicioPrivado";
import { ContextoUsuario } from "../../../security/ContextoUsuario";
import { useFormulario } from "../../../utils/hooks/useFormulario";
import { Link } from "react-router-dom";
import PersonaAutorizacion from "../../../models/PersonaAutorizacion";
import { Button, Modal } from "react-bootstrap";
import FilterAutoriza from "../../../models/FilterAutoriza";
import { MesajeError } from "../../../utils/tools/MesajeError";
import { MesajeSuccess } from "../../../utils/tools/MesajeSuccess";
import { FormatFecha } from "../../../utils/tools/ToolsDate";
import Autorizacion from "../../../models/Autorizacion";
import MiSesion from "../../../models/MiSesion";
import InfoSearch from "../../../models/InfoSearch";
import jwtDecode from "jwt-decode";

//const miUsuario = useContext(ContextoUsuario);
//const idUsuario = miUsuario?.autenticado.idUsuario;
// /utilidades/dominios/ApiBack";

export const ListAutorizaciones = () => {

  let elToken = "";
  let idUsuarioCons =  "";
  let idDep = 0;
  let idDiv = 0;
  let perfil = "";
  if (localStorage.getItem("tokenSingenap")) {
     elToken = String(localStorage.getItem("tokenSingenap"));
     const objJWTRecibido: any = jwtDecode(elToken);
     idUsuarioCons =  String(objJWTRecibido.idUsuario);
     idDep = (objJWTRecibido.idDep);
     idDiv = (objJWTRecibido.idDiv);
     perfil = String(objJWTRecibido.rol);
   }


  //const [fecIni, setFecIni] = useState <Date>(new Date);
  //const [fecFin, setFecFin] = useState <Date>(new Date);
  let fecAct = new Date();
  fecAct.setHours(0);
  fecAct.setHours(fecAct.getHours() - 1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [ready, setReady] = useState<boolean>(false);
  const [enProceso, setEnProceso] = useState<boolean>(false);
  type formHtml = React.FormEvent<HTMLFormElement>;
  
  const [totalPaginas, setTotalPaginas] = useState<number>(0);
  const [paginaActual, setPaginaActual] = useState<number>(0);

  const handlePaginaFinal = () => {
    if (totalPaginas > 0 && paginaActual < totalPaginas - 1) {
      obtenerAutorizaByPerson(totalPaginas - 1);
    }
  };

  const handlePaginaInicial = () => {
    if (paginaActual !== 0)
    {
      obtenerAutorizaByPerson(0);

    }
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 0) {
      obtenerAutorizaByPerson(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    if (paginaActual < totalPaginas - 1) {
      obtenerAutorizaByPerson(paginaActual + 1);
    }
  };

  let {
    idUsuario,
    tipoUsuario,
    page,
    statususer,
    dobleEnlace,
    objeto,
  } = useFormulario<InfoSearch>(
    new InfoSearch("","",0,0,0, 0,5)
  );
   
  const [objAut, setObjAut] = useState<PersonaAutorizacion>(new PersonaAutorizacion( 0,0,0,"","","","","","","","","","","","","","","",new Date(), new Date(),0,"",0,0,0,0,0 ));

  const [arregloAutorizaByPerson, setArregloAutorizaByPerson] = useState<PersonaAutorizacion[]>([]);


  
  
// Función para obtener AutorizaByPerson
  const obtenerAutorizaByPerson = async (pageSol: number) => {
    let resultado;
    let url = ApiBack.AUTORIZACION_OBTENER_LIST;
    // idUsuarioCons =  String(miUsuario?.autenticado.idUsuario);
    // idDep = (miUsuario?.autenticado.idDependencia);
    // idDiv = (miUsuario?.autenticado.idDivision);
    // perfil = String(miUsuario?.autenticado.perfil);  
    const objSearch = new InfoSearch(idUsuario, perfil, idDiv, idDep, tipoUsuario, pageSol, statususer);
    resultado = await ServicioPrivado.peticionPOST(url, objSearch);

    
    setArregloAutorizaByPerson(resultado.content);

    setPaginaActual(resultado.pageable.pageNumber);
    setTotalPaginas(resultado.totalPages);

    setReady(true);
    return resultado;
  };

  useEffect(() => {
    if (!ready) {
      obtenerAutorizaByPerson(paginaActual);
    }
  }, []);

  const verifyForm = async (fh: formHtml) => {
    fh.preventDefault();
    ///setEnProceso(true);
    obtenerAutorizaByPerson(0);
  }





  const borrarAutorizacion = async (codigoAutorizacion: any) => {
    const urlBorrar = ApiBack.AUTORIZACION_ELIMINAR + "/" + codigoAutorizacion;
    const resultado = await ServicioPrivado.peticionDELETE(urlBorrar);
    console.log(resultado);
    if (typeof resultado.message === "undefined") {
      MesajeError("No se puede eliminar la autorización.");
    } else {
      MesajeSuccess( "La autorización ha sido eliminada" );
    }
    obtenerAutorizaByPerson(paginaActual);
  };

  const activatePermiso = async (codigoAutorización: any, accionSol: any) => {

    const aut = arregloAutorizaByPerson.find(autoriza => autoriza.idAutorizaciones === Number(codigoAutorización));
    let fechaInicio = new Date();
    let fechaFinal = new Date();
    if (aut?.fechaInicio)
    {
      fechaInicio = aut?.fechaInicio;
    }
    if (aut?.fechaFinal)
    {
      fechaFinal = aut?.fechaFinal;
    }
    const objUpdate = new Autorizacion(Number(aut?.idAutorizaciones), Number(aut?.idPersonaAutoriza), 
      Number(aut?.idAutorizado),fechaInicio,fechaFinal,
      String(aut?.motivo),String(aut?.direccion),"","",
      String(aut?.placa),Number(accionSol));

    // constructor(idAut: number, idUsu: number, idVis: number, fecIni: Date, fecFin: Date, mot: string, 
    //   dir: string, fCed: string, fAut: string, placa: string, autorizado: number)
  
    const url = ApiBack.UPDATE_AUTORIZACION ;
    const resultado = await ServicioPrivado.peticionPUT(url, objUpdate);
    obtenerAutorizaByPerson(paginaActual);
  }


  
  return (
    <main id="main" className="main">
      <div className="col-lg-11 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
          <Link to={"/dashboard/newPermiso/"} title="Nueva autorización">
            <i
              className="fa-regular fa-address-card fa-2xl"
              style={{ color: "#0a08bd" }}
              aria-label="Crear nueva autorización"
            ></i>
          </Link>
          <span className="fs-4">Administración de autorizaciones</span>
        </header>

        <p className="fs-6 center align-content-center">
          <div className="center align-content-center">
            <div className="d-flex align-items-center justify-content-center">
              <Form
                noValidate
                className="row g-3"
                validated={enProceso}
                onSubmit={verifyForm}
              >
                <div className="col-auto">
                  <Form.Group controlId="tipoUsuario">
                    <Form.Select
                      required
                      name="tipoUsuario"
                      value={tipoUsuario}
                      onChange={dobleEnlace}
                      className="form-control"
                    >
                      <option value={0}>Visitante</option>
                      <option value={1}>Responsable</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-auto">
                  <Form.Group controlId="idUsuario">
                    <Form.Control
                      required
                      type="text"
                      name="idUsuario"
                      className="form-control"
                      value={idUsuario}
                      onChange={dobleEnlace}
                      placeholder="Documento"
                    />
                  </Form.Group>
                </div>
                <div className="col-auto">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      obtenerAutorizaByPerson(0);
                    }}
                  >
                    Filtrar
                  </Button>
                </div>
              </Form>
            </div>

            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ width: "3%" }} className="text-center">
                          {" "}
                        </th>
                        <th style={{ width: "7%" }} className="text-center">
                          Documento
                        </th>
                        <th style={{ width: "26%" }} className="text-center">
                          Apellido y Nombre
                        </th>
                        <th style={{ width: "9%" }} className="text-center">
                          Teléfono
                        </th>
                        <th style={{ width: "10%" }} className="text-center">
                          Correo
                        </th>
                        <th style={{ width: "14%" }} className="text-center">
                          Motivo
                        </th>
                        <th style={{ width: "8%" }} className="text-center">
                          Lugar
                        </th>
                        <th style={{ width: "7%" }} className="text-center">
                          Desde{" "}
                        </th>
                        <th style={{ width: "7%" }} className="text-center">
                          Hasta
                        </th>
                        <th style={{ width: "8%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {arregloAutorizaByPerson.map(
                        (personaAutorizacion, indice) => (
                          <tr key={indice}>
                            <td>
                              {personaAutorizacion.autorizado === 1 &&
                                fecAct <=
                                  new Date(personaAutorizacion.fechaFinal) && (
                                  <>
                                    <a
                                      title="Desactivar acceso"
                                      href="/#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        activatePermiso(
                                          personaAutorizacion.idAutorizaciones,
                                          0
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa-regular fa-thumbs-up"
                                        style={{ color: "#006600" }}
                                      ></i>
                                    </a>
                                  </>
                                )}
                              {personaAutorizacion.autorizado !== 1 &&
                                fecAct <=
                                  new Date(personaAutorizacion.fechaFinal) && (
                                  <>
                                    <a
                                      title="Activar acceso"
                                      href="/#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        activatePermiso(
                                          personaAutorizacion.idAutorizaciones,
                                          1
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa-regular fa-thumbs-down"
                                        style={{ color: "#660000" }}
                                      ></i>
                                    </a>
                                  </>
                                )}

                              {personaAutorizacion.autorizado === 1 &&
                                fecAct >
                                  new Date(personaAutorizacion.fechaFinal) && (
                                  <>
                                    <i
                                      className="fa-solid fa-thumbs-up"
                                      style={{ color: "#006600" }}
                                    ></i>
                                  </>
                                )}
                              {personaAutorizacion.autorizado !== 1 &&
                                fecAct >
                                  new Date(personaAutorizacion.fechaFinal) && (
                                  <>
                                    <i
                                      className="fa-solid fa-thumbs-down"
                                      style={{ color: "#660000" }}
                                    ></i>
                                  </>
                                )}
                            </td>
                            <td>{personaAutorizacion.documentoAutorizado}</td>
                            <td>
                              {personaAutorizacion.apellidosAutorizado}{" "}
                              {personaAutorizacion.nombreAutorizado}
                            </td>
                            <td>{personaAutorizacion.telefonoAutorizado}</td>
                            <td>{personaAutorizacion.correoAutorizado}</td>
                            <td>{personaAutorizacion.motivo}</td>
                            <td>{personaAutorizacion.direccion}</td>
                            <td>
                              {FormatFecha(personaAutorizacion.fechaInicio)}
                            </td>
                            <td>
                              {FormatFecha(personaAutorizacion.fechaFinal)}{" "}
                            </td>
                            <td>
                              <Link
                                to={
                                  "/dashboard/detailautoriza/" +
                                  personaAutorizacion.idAutorizaciones
                                }
                              >
                                <i className="fa-solid fa-magnifying-glass fa-sm"></i>
                              </Link>{" "}
                              {fecAct <=
                                new Date(personaAutorizacion.fechaInicio) &&
                                idUsuarioCons ===
                                  personaAutorizacion.documentoAutoriza && (
                                  <>
                                    <a
                                      href="/#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setShow(true);
                                        setObjAut(personaAutorizacion);
                                      }}
                                    >
                                      <i
                                        className="fa-solid fa-trash-can fa-sm"
                                        style={{ color: "#990000" }}
                                      ></i>
                                    </a>{" "}
                                    <Link
                                      to={
                                        "/dashboard/updateautoriza/" +
                                        personaAutorizacion.idAutorizaciones
                                      }
                                    >
                                      <i
                                        className="fa-regular fa-pen-to-square"
                                        style={{ color: "#006600" }}
                                      ></i>
                                    </Link>
                                  </>
                                )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-end">
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePaginaInicial();
                        }}
                      >
                        <i className="fa-solid fa-backward-step"></i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePaginaAnterior();
                        }}
                      >
                        <i className="fa-solid fa-caret-left"></i>
                      </a>
                    </li>
                    <li className="page-item">{}</li>
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      {paginaActual + 1} de {totalPaginas}
                    </a>

                    <li className="page-item">{}</li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePaginaSiguiente();
                        }}
                      >
                        <i className="fa-solid fa-caret-right"></i>
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePaginaFinal();
                        }}
                      >
                        <i className="fa-solid fa-forward-step"></i>
                      </a>
                    </li>
                  </ul>
                </nav>

                {/* Modal para eliminar */}
                {/* *********************************************************************************/}
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Eliminar Autorización</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    ¿Realmente desea eleminar la autorizacion?
                    <br />
                    <strong>
                      {objAut.nombreAutorizado} {objAut.apellidosAutorizado} -
                      desde {objAut.fechaInicio.toString()} hasta{" "}
                      {objAut.fechaFinal.toString()}
                    </strong>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={(e) => {
                        setShow(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        borrarAutorizacion(objAut.idAutorizaciones);
                        setShow(false);
                      }}
                    >
                      Eliminar
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* *********************************************************************************/}
              </div>
            </div>
          </div>
        </p>

        <div className="col-lg-11 mx-auto p-4 py-md-5"></div>
      </div>
    </main>
  );
};