class InfoSearch {
  public idUsuario: string;
  public perfil: string;
  public idDivision: number;
  public idDependencia: number;
  public tipoUsuario: number;
  public page: number;
  public statususer: number;
  

  constructor(cod: string,  per: string, idDivision: number, idDependencia: number, 
    tipoUsuario: number, page: number, status: number) {
    this.idUsuario = cod;
    this.perfil = per;
    this.idDivision = idDivision;
    this.idDependencia = idDependencia;
    this.tipoUsuario = tipoUsuario;
    this.page = page;
    this.statususer = status;
  }
}

export default InfoSearch;