import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { ContextoUsuario } from "../../../security/ContextoUsuario";
import { useFormulario } from "../../../utils/hooks/useFormulario";

import { propUsuario } from "../../../models/MisInterfaces";

import logoEnap from "../../../../assets/image/logo_enap.png";
import ApiBack from "../../../utils/domines/ApiBack";
import ServicioPrivado from "../../../services/ServicioPrivado";
import { MesajeError } from "../../../utils/tools/MesajeError";
import Divisiones from "../../../models/Divisiones";

// Para los mensajes, no olvidar !!!
// *******************************************************************
export const EditDivision = () => {
  // Variables
  // *******************************************************************
  const navigate = useNavigate();
  type formHtml = React.FormEvent<HTMLFormElement>;
  const { actualizar } = useContext(ContextoUsuario) as propUsuario;
  const [enProceso, setEnProceso] = useState<boolean>(false);
  const [readyUser, setReadyUser] = useState(false);
  const [validData, setValidData] = useState<boolean>(false);

  const [imagePreview, setImagePreview] = useState<any>("");
  const [base64, setBase64] = useState<string>();


  let { idDivSearch } = useParams();
  // Manejo de formulario con Hook personalizado (sin librerias externas)
  // *******************************************************************
  let {
    idDivision,
    idDependencia,
    nombre,
    dobleEnlace,
    objeto,
  } = useFormulario<Divisiones>(
    new Divisiones(0,0,"")
  );


  // Función flecha para resetear variables y limpiar cajas del formulario
  // *******************************************************************
  const limpiarCajas = (formulario: HTMLFormElement) => {
    formulario.reset();
    objeto.nombre = "";

    //formulario.tipoDocumento.value = "1";
    formulario.nombre.value = "";

    formulario.classList.remove("was-validated");
  };


  const verifyForm = async (fh: formHtml) => {
    /**
     * previene acciones por defecto de los formularios html
     */
    fh.preventDefault();
    setEnProceso(true);
    /**
     * se captura la información del formulario actual
     */
    const formActual = fh.currentTarget;
    formActual.classList.add("was-validated");
    if (formActual.checkValidity() === false) {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
    } else {
      //console.log("Conexión al backend");
      //const fromBackend = await ServicioPublico.createUser(objeto);
      //console.log(fromBackend);

      // Código para crear el usuario consumiendo servicio del back y usando sha512
      // *******************************************************************
      const url = ApiBack.DIVISION_UPDATE;
      const resultado = await ServicioPrivado.peticionPUT(url, objeto); ;//await ServicioPublico.crearUsuario(objeto);
      
      if (resultado) {
        const urlBack = "/dashboard/listdivisiones/" + idDependencia;
        navigate(urlBack);

//        navigate("/login");
        setEnProceso(false);
      } else {
        //limpiarCajas(formActual);
        MesajeError("No se puede actualizar la Division");
        //MensajeToastify("Error", "No se puede crear el usuario. Correo o perfil incorrectos.", 7000);
      }
    }
  };
  


  const obtenerDivision = async () => {

    const url = ApiBack.DIVISION_SEARCH + "/" + idDivSearch;

    const resultado = await ServicioPrivado.peticionGET(url);

    if (resultado.nombre) {
      objeto.nombre = resultado.nombre;
      objeto.idDivision = resultado.idDivision;
      objeto.idDependencia = resultado.idDependencia;
      setValidData(true);
    } else {
      
      setValidData(false);
    }
  
};


  
  useEffect(() => {
    if (!readyUser) {
      obtenerDivision();
    }
  }, []);


  return (
    <div>
      <main>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src={logoEnap} alt="" />
                      <span className="d-none d-lg-block">
                        Sistema de Ingreso ENAP
                      </span>
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Editar datos de la División
                        </h5>
                      </div>

                      <Form
                        noValidate
                        className="row g-3"
                        validated={enProceso}
                        onSubmit={verifyForm}
                      >

                        <div className="col-12">
                          <Form.Group controlId="nombre">
                            <Form.Label>Nombre</Form.Label>

                            <Form.Control
                              required
                              type="text"
                              name="nombre"
                              className="form-control"
                              value={nombre}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                            Nombre de la División es obligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Actualizar
                          </button>
                        </div>
                      </Form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};
