class MiSesion {
  public idUsuario: string;
  public nombre: string;
  public mail: string;
  public perfil: string;
  public idDivision: number;
  public idDependencia: number;
  

  constructor(cod: string, name: string, cor: string, per: string, idDivision: number, idDependencia: number) {
    this.idUsuario = cod;
    this.nombre = name;
    this.mail = cor;
    this.perfil = per;
    this.idDivision = idDivision;
    this.idDependencia = idDependencia;
  }
}

export default MiSesion;