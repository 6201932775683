
import { DataUser } from "../views/privadas/users/DataUser";
import { ListAutorizaByPerson } from '../views/privadas/Autorizaciones/ListAutorizaByPerson';




export const Bienvenida = () => {
  

  //const [dataIdUsuario, setDataIdUsuario] = useState<string>("hola");

  
  
 
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Bienvenido</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Inicio</a>
            </li>
          </ol>
        </nav>
      </div>

      <div className="col-lg-11 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
          <i
            className="fa-regular fa-address-card fa-2xl"
            style={{ color: "#0a08bd" }}
          ></i>
          <span className="fs-4">Bienvenido al sistema de Autorización de Acceso de la ENAP</span>
        </header>

        <main>
          <h2>Datos Personales</h2>
          <p className="fs-4">
            
           
            {DataUser()}
            
          </p>

          <hr className="col-3 col-md-2 mb-5" />

              <h2>Ultimas autorizaciones </h2>
              <p className="fs-6 center">
               {ListAutorizaByPerson()}
              </p>
          <div className="col-lg-11 mx-auto p-4 py-md-5">

          </div>
        </main>
      </div>
    </main>
  );
};
