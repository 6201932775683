import Form from "react-bootstrap/Form";


import { useNavigate } from "react-router-dom";

import { useState, useEffect, ChangeEvent, useContext } from 'react';

import ApiBack from "../../../utils/domines/ApiBack";  

import ServicioPrivado from "../../../services/ServicioPrivado";
import { useFormulario } from "../../../utils/hooks/useFormulario";

import { ToastContainer } from "react-toastify";
import CrearPersonaAutorizacion from "../../../models/CrearPersonaAutorizacion";
import { Button, Col, Row } from "react-bootstrap";
import Roles from "../../../models/Roles";
import { FormatFecha, corregirFecha, validaDifFechas, validaFechaInicio } from '../../../utils/tools/ToolsDate';
import { MesajeError } from "../../../utils/tools/MesajeError";
import { ContextoUsuario } from "../../../security/ContextoUsuario";

//const miUsuario = useContext(ContextoUsuario);
//const idUsuario = miUsuario?.autenticado.idUsuario;
// /utilidades/dominios/ApiBack";

export const CreateAutorizacion = () => {


  const miUsuario = useContext(ContextoUsuario);
 
  const idUsuario =  String(miUsuario?.autenticado.idUsuario);
  const perfilUsuario = miUsuario?.autenticado.perfil;

  type formHtml = React.FormEvent<HTMLFormElement>;
  const navigate = useNavigate();

  const [validData, setValidData] = useState<boolean>(false);
  const [validResponsable, setValidResponsable] = useState<boolean>(false);
  const [validDocument, setValidDocument] = useState<boolean>(false);
  const [enProceso, setEnProceso] = useState<boolean>(false);
  const [textToShow, setTextToShow] = useState<string>("");
  const [base64, setBase64] = useState<string>();
  const [imagePreview, setImagePreview] = useState<any>("");
  const [dirBase, setDirBase] = useState<string>("");
  const [userSystem, setUserSystem] = useState<boolean>(false);

  

  let {
    idAutorizaciones,
    idPersonaAutoriza,
    idAutorizado,
    documentoAutoriza,
    motivo,
    direccion,
    fotoCedula,
    fotoAutorizado,
    documentoAutorizado,
    nombreAutorizado,
    apellidosAutorizado,
    telefonoAutorizado,
    correoAutorizado,
    nombreAutoriza,
    apellidosAutoriza,
    telefonoAutoriza,
    correoAutoriza,
    placa,
    fechaInicio,
    fechaFinal,
    status,
    idRol,
    autorizado,
    dobleEnlace,
    objeto,
  } = useFormulario<CrearPersonaAutorizacion>(
    new CrearPersonaAutorizacion(0,0,0,idUsuario,"","","","","","","","","","","","","","",new Date(), new Date(),0,"",0,0,0,0,0)
  );

  const defineStatusAutorizacion = () => {
    if (perfilUsuario === "Administrador" || perfilUsuario === "Asignatario"  || perfilUsuario === "Validador" )
    { 
      objeto.autorizado = 1;
    }else{
      objeto.autorizado = 0;
    }
      
  }

  const limpiarCampos = () => {
    objeto.documentoAutoriza = idUsuario;
    objeto.motivo = "";
    objeto.direccion = dirBase; 
    objeto.fotoCedula = "";
    objeto.fotoAutorizado = "";
    
    objeto.nombreAutorizado = "";
    objeto.apellidosAutorizado = "";
    objeto.telefonoAutorizado = "";
    objeto.correoAutorizado = "";
    objeto.nombreAutoriza = "";
    objeto.apellidosAutoriza = "";
    objeto.telefonoAutoriza = "";
    objeto.correoAutoriza = "";
    objeto.placa = "";
    objeto.fechaInicio = new Date();
    objeto.fechaFinal = new Date();

  };
  const limpiarCajas = (formulario: HTMLFormElement) => {
    formulario.reset();
    limpiarCampos();
    objeto.documentoAutorizado = "";

    //formulario.tipoDocumento.value = "1";
    formulario.documentoAutoriza.value = "";
    formulario.motivo.value = "";
    formulario.direccion.value = dirBase;
    formulario.fotoCedula.value = "";
    formulario.fotoAutorizado.value = "";
    formulario.documentoAutorizado.value = "";
    formulario.nombreAutorizado.value = "";
    formulario.apellidosAutorizado.value = "";
    formulario.telefonoAutorizado.value = "";
    formulario.correoAutorizado.value = "";
    formulario.nombreAutoriza.value = "";
    formulario.apellidosAutoriza.value = "";
    formulario.telefonoAutoriza.value = "";
    formulario.correoAutoriza.value = "";
    formulario.placa.value = "";
    formulario.fechaInicio.value = FormatFecha(new Date(),1);
    formulario.fechaFinal.value = FormatFecha(new Date(),1);

    formulario.classList.remove("was-validated");
  };


  const generarImage = (e: any) => {
    console.log("Llegada ", e);
    let file = e.target.files[0];
    console.log("File ", file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
//      reader.readAsDataURL(file);
      reader.onload = _handleReaderLoaded
     // reader.readAsBinaryString(file)
      setImagePreview(reader.result)
    }
  
  };

  const _handleReaderLoaded = (readerEvt: any) => {
    let binaryString =  readerEvt.target.result; //"data:image/png;base64," + readerEvt.target.result;
    objeto.fotoAutorizado = binaryString as string;
    setBase64(binaryString)
  };

  

  const [arregloRoles, setArregloRoles] = useState<Roles[]>([]);
  const obtenerRoles = async () => {
    let resultado;
    let url = ApiBack.ROLES_OBTENERINVITADOS;

    resultado = await ServicioPrivado.peticionGET(url);
    setArregloRoles(resultado);
    return resultado;
  };
  


  
  const buscarPersonaField = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    
    objeto.documentoAutorizado = inputValue;
    buscarPersona();
  };

  const buscarPersona = async () => {

      const url = ApiBack.OBTENER_AUTORIZADO;
      const idAdo = objeto.documentoAutorizado;
      //window.location.reload();

      limpiarCampos();
      const resultado = await ServicioPrivado.peticionPOST(url, objeto);
      objeto.documentoAutorizado = idAdo;
      setValidDocument(true);



      if (resultado.nombreAutorizado) {
        objeto.documentoAutoriza = resultado.documentoAutoriza;
        objeto.motivo = resultado.motivo;
        objeto.direccion = dirBase;
        objeto.fotoCedula = resultado.fotoCedula;
        objeto.fotoAutorizado = resultado.fotoAutorizado;
        objeto.documentoAutorizado = resultado.documentoAutorizado;
        objeto.nombreAutorizado = resultado.nombreAutorizado;
        objeto.apellidosAutorizado = resultado.apellidosAutorizado;
        objeto.telefonoAutorizado = resultado.telefonoAutorizado;
        objeto.correoAutorizado = resultado.correoAutorizado;
        objeto.nombreAutoriza = resultado.nombreAutoriza;
        objeto.apellidosAutoriza = resultado.apellidosAutoriza;
        objeto.telefonoAutoriza = resultado.telefonoAutoriza;
        objeto.correoAutoriza = resultado.correoAutoriza;
        objeto.status = resultado.status;
        objeto.idDependencia = resultado.idDependencia;
        objeto.idDivision = resultado.idDivision;
        objeto.idRol = resultado.idRol;

        const rolAutorizado = arregloRoles.find(rol => rol.idRol === Number(objeto.idRol));

        let userValido = true;
        if (rolAutorizado) 
        {
          userValido = true;
          setUserSystem(false);
        }else{
          // el usuario tiene un rol del sistema, no debe ser autorizado por este medio
          userValido = false;
          setUserSystem(true);
        }


        if (userValido)

        { 
          if (objeto.status === 2)
          {
            setTextToShow("La persona identificada como " + objeto.nombreAutorizado + " " + objeto.apellidosAutorizado + 
            ", Telefono: " + objeto.telefonoAutorizado + ", Correo: " + objeto.correoAutorizado +
            ", No tiene autorizacion de ingreso a la Isla Naval, cualquier duda por favor pongase en contacto con la división de seguridad.");
          }else{
            setTextToShow("Nombre: " + objeto.nombreAutorizado +" " + objeto.apellidosAutorizado +
            ", Telefono: " + objeto.telefonoAutorizado + ", Correo: " + objeto.correoAutorizado);
          }
          setValidData(true);
        } else{
          setTextToShow("El documento pertenece a un usuario del sistema, no debe ser autorizado por este medio.");
          setValidData(true);
          setValidDocument(false);
        }       
        
      } else {
        
        setValidData(false);
      }
    
  };


  const verifyForm = async (fh: formHtml) => {
    /**
     * previene acciones por defecto de los formularios html
     */
    defineStatusAutorizacion();
    fh.preventDefault();
    setEnProceso(true);
    /**
     * se captura la información del formulario actual
     */
    const formActual = fh.currentTarget;
    formActual.classList.add("was-validated");
    const rolAutorizado = arregloRoles.find(rol => rol.idRol === Number(objeto.idRol));

    if (!validaFechaInicio(fechaInicio))
    {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
      MesajeError("Fecha inicial no debe ser mayor a un mes de la fecha actual, ni menor a la fecha actual del sistema");
    }else if(!validaDifFechas(fechaInicio, fechaFinal, rolAutorizado?.nombreRol)){
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
      MesajeError("El periodo del permiso no es valido");
    }else if (fechaFinal < fechaInicio)
    {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
      MesajeError("Fecha final debe ser mayor a la fecha de inicio de la autorizacion");
    }else if (formActual.checkValidity() === false) {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
    } else {
      // Código para crear el usuario consumiendo servicio del back y usando sha512
      // *******************************************************************
     
      if (validData || validDocument) {
        const url = ApiBack.CREAR_AUTORIZACION;
        //  console.log("Objeto a enviar " + objeto);

        // objeto.fechaFinal = corregirFecha(objeto.fechaFinal);
        // objeto.fechaInicio = corregirFecha(objeto.fechaInicio);

        const resultado = await ServicioPrivado.peticionPOST(url, objeto);
        //console.log("despues de enviar " + resultado);
        if (resultado.nombreAutorizado) {
          navigate("/dashboard/listPermisos");
          setEnProceso(false);
        } else {
          // limpiarCajas(formActual);
          MesajeError("No se puede crear la autorización. Verifique que no tenga una autorización dentro del rango de fechas");
          //MensajeToastify("Error", "No se puede crear el usuario. Correo o perfil incorrectos.", 7000);
        }
      } else {
        buscarPersona();
      }
    }
  };

  const buscarResponsable = async () => {
    if (!validResponsable)
    {
      
      const url = ApiBack.USUARIO_OBTENERUNO + "/" + idUsuario;

      const resultado = await ServicioPrivado.peticionGET(url);

      if (resultado.nombres) {

        setDirBase(resultado.edificio + " " + resultado.numeroEdificio);
        setValidResponsable(true);
      }

    }
  }

  useEffect(() => {
    if (!validResponsable) {
      defineStatusAutorizacion();
      obtenerRoles();
      buscarResponsable();
    }
  }, []);

  return (
      
    <div>
    <main>
      <div className="container">
        <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 d-flex flex-column align-items-center justify-content-center">
                

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Generar Autorización
                      </h5>
                    </div>

                    <Form
                      noValidate
                      className="row g-3"
                      validated={validData}
                      onSubmit={verifyForm}
                    >

                      <div className="col-12">
                        <Form.Group controlId="documentoAutorizado">
                          <Form.Label>Numero de documento</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="documentoAutorizado"
                            className="form-control"
                            onChange={buscarPersonaField}
                          />
                          <Form.Control.Feedback type="invalid">
                          Numero de documento requerido
                          </Form.Control.Feedback>
                          <Button onClick={buscarPersona}>Buscar</Button>
                        </Form.Group>
                      </div>

                    {validData && validDocument && status === 2 && ( <>
                      <div className="col-12">
                        <p className="text-center">
                          {textToShow}
                        </p>
                      </div>

                      <div id="imagenprevia" className="d-flex align-items-center justify-content-center">
                        <img src={fotoAutorizado} alt="FOTO" id="imgPreView" height={155} width={"auto"}></img>
                      </div>

                    </>)}

                    {userSystem  && ( <>
                      <div className="col-12">
                        <p className="text-center">
                          {textToShow}
                        </p>
                      </div>

                      
                    </>)}

                    {validData && validDocument && status !== 2 && ( <>
                      <div className="col-12">
                        <p className="text-center">
                          {textToShow}
                        </p>
                      </div>

                        <div id="imagenprevia" className="d-flex align-items-center justify-content-center">
                        <img src={fotoAutorizado} alt="FOTO" id="imgPreView" height={155} width={"auto"}></img>
                      </div>


                      
                    </>)}

                    {!validData && validDocument && status !== 2 && ( <>
                    
                      <div className="col-12">
                        <Form.Group controlId="nombreAutorizado">
                          <Form.Label>Nombre completo</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="nombreAutorizado"
                            className="form-control"
                            value={nombreAutorizado}
                            onChange={dobleEnlace}
                          />
                          <Form.Control.Feedback type="invalid">
                            Nombre requerido
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-12">
                        <Form.Group controlId="apellidosAutorizado">
                          <Form.Label>Apellidos</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            name="apellidosAutorizado"
                            className="form-control"
                            value={apellidosAutorizado}
                            onChange={dobleEnlace}
                          />
                          <Form.Control.Feedback type="invalid">
                            Apellido requerido
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      <div className="col-12">
                        <Form.Group controlId="correoAutorizado">
                          <Form.Label>Correo electrónico</Form.Label>
                          <div className="input-group has-validation">
                            <span className="input-group-text">@</span>
                            <Form.Control
                              required
                              type="email"
                              name="correoAutorizado"
                              className="form-control"
                              value={correoAutorizado}
                              onChange={dobleEnlace}
                              />
                            <Form.Control.Feedback type="invalid">
                              correo electrónico requerido
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-12">
                        <Form.Group controlId="telefonoAutorizado">
                          <Form.Label>Numero de telefono</Form.Label>
                          <div className="input-group has-validation">
                            <Form.Control
                              required
                              type="text"
                              name="telefonoAutorizado"
                              className="form-control"
                              value={telefonoAutorizado}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              Numero de telefono requerido
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </div>

                        <div id="imagenprevia" className="d-flex align-items-center justify-content-center">
                        <img src={fotoAutorizado} alt="FOTO" id="imgPreView" height={155} width={"auto"}></img>
                      </div>

                        <div className="col-12">
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="filePhoto"
                          >
                            <Form.Label column sm={2}>
                              Foto:
                            </Form.Label>
                            <Col sm={10}>
                              <Form.Control
                                type="file"
                                name="filePhoto"
                                onChange={generarImage}
                              />
                              <Form.Control.Feedback type="invalid">
                                Seleccione una foto
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </div>
                     

                      </>)}

                      {validDocument && status !== 2 && ( <>

                      <div className="col-12">
                        <p className="small mb-0">
                          Datos de la Autorización{" "}
                          
                        </p>
                      </div>

                      <div className="col-12">
                        <Form.Group controlId="idRol">
                          <Form.Label>Tipo de Vivitante</Form.Label>

                          <Form.Select
                            required
                            name="idRol"
                            value={idRol}
                            onChange={dobleEnlace}
                            className="form-control"
                          >
                            <option value="">
                              Seleccione el rol del usuario
                            </option>
                            {arregloRoles.map((rolData, indice) => ( 
                            <option value={rolData.idRol}>{rolData.nombreRol}</option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Seleccione el rol del usuario
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>


                      <div className="col-12">
                        <Form.Group controlId="motivo">
                          <Form.Label>Motivo Autorización</Form.Label>
                          <div className="input-group has-validation">
                            <Form.Control
                              required
                              type="text"
                              name="motivo"
                              className="form-control"
                              value={motivo}
                              onChange={dobleEnlace}
                              />
                            <Form.Control.Feedback type="invalid">
                              El motivo requerido
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-12">
                        <Form.Group controlId="direccion">
                          <Form.Label>A donde se dirige</Form.Label>
                          <div className="input-group has-validation">
                            <Form.Control
                              required
                              type="text"
                              name="direccion"
                              className="form-control"
                              value={direccion}
                              onChange={dobleEnlace}
                              />
                            <Form.Control.Feedback type="invalid">
                              El lugar de visita requerido
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-12">
                        <Form.Group controlId="placa">
                          <Form.Label>Placa Vehiculo</Form.Label>
                          <Form.Control
                            type="text"
                            name="placa"
                            className="form-control"
                            value={placa}
                            onChange={dobleEnlace}
                          />
                        </Form.Group>
                      </div>

                      <div className="col-12">
                        <Form.Group controlId="fechaInicio">
                          <Form.Label>Fecha de Inicio </Form.Label>
                          <div className="input-group has-validation">
                            <Form.Control
                              required
                              type="date"
                              name="fechaInicio"
                              className="form-control"
                              value={FormatFecha((fechaInicio),1)}
                              onChange={dobleEnlace}
                              />
                            <Form.Control.Feedback type="invalid">
                            La fecha y hora en que inicia la autorizacion requerida
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </div>

                      <div className="col-12">
                        <Form.Group controlId="fechaFinal">
                          <Form.Label>Fecha de Finaliza </Form.Label>
                          <div className="input-group has-validation">
                            <Form.Control
                              required
                              type="date"
                              name="fechaFinal"
                              className="form-control"
                              value={FormatFecha((fechaFinal),1)}
                              onChange={dobleEnlace}
                              />
                            <Form.Control.Feedback type="invalid">
                              La fecha y hora en que termina la autorizacion requerida
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>
                      </div>

               

                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100"
                          type="submit"
                        >
                          Crear solicitud
                        </button>
                      </div>
                      </>)}
                    </Form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <ToastContainer />
  </div>
  );
};