class PersonaCompleta
{
  public idPersona: number;
  public tipoDocumento: number;
  public documento: string;
  public nombres: string;
  public apellidos: string;
  public telefono: string;
  public correo: string;
  public foto: string;
  public password: string;
  public idRol: number;
  public fechaInicio: Date;
  public fechaTermino: Date;
  public nombreRol:string;
  public edificio:string;
  public numeroEdificio: string;
  public dependenciaNombre:string;
  public idPersonaDireccion:number;
  public idPersonaRol:number;
  public idDependencia:number;
  public status:number;
  public grado: string;
  public idDivision:number;
  

  constructor(id: number, tdoc: number, doc: string, nom: string, ape: string, tel: string, mail: string, 
    fot: string, pas: string, idRol: number, fechaInicio: Date,
    fechaTermino: Date, nombreRol:string, edificio:string, numeroEdificio: string, 
    dependenciaNombre:string, idPersonaDireccion:number, idDependencia:number, 
    idPersonaRol:number,status:number, grado: string, idDivision:number)
  {
    this.idPersona = id;
    this.apellidos = ape;
    this.correo = mail;
    this.nombres = nom;
    this.tipoDocumento = tdoc; 
    this.documento = doc;
    this.foto = fot;
    this.telefono = tel;
    this.password = pas;
    this.idRol = idRol;
    this.fechaInicio =fechaInicio; 
    this.fechaTermino = fechaTermino; 
    this.nombreRol = nombreRol;
    this.edificio = edificio;
    this.numeroEdificio = numeroEdificio; 
    this.dependenciaNombre = dependenciaNombre;
    this.idPersonaDireccion = idPersonaDireccion;
    this.idDependencia = idDependencia;
    this.idPersonaRol = idPersonaRol;
    this.status = status;
    this.grado = grado;
    this.idDivision = idDivision;
    
  }
}

export default PersonaCompleta; 
