import { FC, useState } from "react";

import jwtDecode from "jwt-decode";
import MiSesion from "../models/MiSesion";
import { propSesion } from "../models/MisInterfaces";
import { ContextoUsuario } from "./ContextoUsuario";


const UsuarioSesion: FC<propSesion> = ({ children }) => {
  let usuarioCargado = new MiSesion("", "", "", "", 0, 0);
  const actualizar = (objUsuario: MiSesion) => { setAutenticado(objUsuario); };

  if (localStorage.getItem("tokenSingenap")) {
    const elToken = String(localStorage.getItem("tokenSingenap"));
    try {
      const objJWTRecibido: any = jwtDecode(elToken);
      usuarioCargado = new MiSesion( objJWTRecibido.idUsuario, objJWTRecibido.nombre, 
        objJWTRecibido.mail, objJWTRecibido.rol, objJWTRecibido.idDivision, objJWTRecibido.idDependencia );
    } catch {
      console.log("Error");
    }
  }
  const [autenticado, setAutenticado] = useState<MiSesion>(usuarioCargado);

  return (
    <ContextoUsuario.Provider value={{ autenticado, actualizar }}>
      {children}
    </ContextoUsuario.Provider>
  );
};

export default UsuarioSesion;
