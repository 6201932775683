export const FormatFecha = (fechaDate: Date, formato = 0) => {
  if (fechaDate === null )
    return "--"
  
  const fechaObjeto = new Date(fechaDate);

  // console.log("fechaObjeto antes: " + fechaObjeto);

  if (fechaObjeto.getTimezoneOffset() !== 0)
  {
    fechaObjeto.setHours(fechaObjeto.getHours() + ( (fechaObjeto.getTimezoneOffset() / 60)));
  }
  // console.log("fechaObjeto despues: " + fechaObjeto);

  
  const dia = fechaObjeto.getDate().toString().padStart(2, "0");
  const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, "0"); // Se suma 1 porque los meses van de 0 a 11
  const año = fechaObjeto.getFullYear();
  
  // Crear la cadena con el formato DD-MM-YYYY
  let fechaFormateada = `${dia}-${mes}-${año}`;
  //fechaFormateada = fechaObjeto;
  if (formato !== 0)
  {
    fechaFormateada = `${año}-${mes}-${dia}`;
  }
  return fechaFormateada;
}

export const corregirFecha = (fechaDate: Date) => {
  const fechaObjeto = new Date(fechaDate);

  if (fechaObjeto.getTimezoneOffset() !== 0)
  {
    fechaObjeto.setHours( 1 + (fechaObjeto.getTimezoneOffset() / 60));
  }
  return fechaObjeto;
}

  // validar que el permiso no se cree 30 dias posterior a la fecha actual
  export const validaFechaInicio = (fechaIngresada: Date): boolean => {
    // Obtener la fecha actual del sistema
    const fechaActual = new Date();
    const fechaIng = new Date(fechaIngresada);
  
    // Restarle 30 días a la fecha actual
    const fechaLimite = new Date();
    fechaLimite.setDate(fechaActual.getDate() + 25);
    fechaActual.setDate(fechaActual.getDate() - 1);
    // console.log("fechaLimite " + fechaLimite);
    // console.log("fechaIngresada " + fechaIngresada);
    // console.log("fechaIng " + fechaIng);
    // console.log("fechaActual " + fechaActual);
  
    // Comparar la fecha ingresada con la fecha límite
    return fechaIng < fechaLimite && fechaIng >= fechaActual;
  }

  // Validar que la fecha de fin del permiso no exceda 15 dias
  export const validaDifFechas = (fechaInicio: Date, fechaFinal: Date, perfilUsuario: string = "Invitado"): boolean => {
    const fechaLimite = new Date(fechaInicio);
    const fechaIni = new Date(fechaInicio);
    const fechaFin = new Date(fechaFinal);
    // Obtener la fecha de inicio y sumarle 15 días
    let diferenciaFechas = 15;
    if (perfilUsuario === "Familiar")
    {
      diferenciaFechas = 15;
    }else if (perfilUsuario === "Residente")
    {
      const fechaHoy = new Date();
      // Obtenemos la fecha del primer día del año
      const startOfYear = new Date(fechaHoy.getFullYear(), 0, 0);
      // Calculamos la diferencia en milisegundos entre la fecha dada y el primer día del año
      const diff = fechaHoy.getTime() - startOfYear.getTime();
      // Convertimos la diferencia a días redondeando hacia abajo
      const oneDay = 1000 * 60 * 60 * 24;
      diferenciaFechas = (365 - Math.floor(diff / oneDay));
    }


    fechaLimite.setDate(fechaIni.getDate() + diferenciaFechas);
  
    // Comparar la fecha final con la fecha límite
    return fechaFin <= fechaLimite;
  }

