import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";

import ApiBack from "../../../utils/domines/ApiBack";  

import ServicioPrivado from "../../../services/ServicioPrivado";
import { useFormulario } from "../../../utils/hooks/useFormulario";
import { Link } from "react-router-dom";
import PersonaAutorizacion from "../../../models/PersonaAutorizacion";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import FilterAutoriza from "../../../models/FilterAutoriza";
import { FormatFecha } from '../../../utils/tools/ToolsDate';
import { MesajeError } from "../../../utils/tools/MesajeError";
import { MesajeSuccess } from "../../../utils/tools/MesajeSuccess";

//const miUsuario = useContext(ContextoUsuario);
//const idUsuario = miUsuario?.autenticado.idUsuario;
// /utilidades/dominios/ApiBack";

export const ListAutorizacionesCap = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [ready, setReady] = useState<boolean>(false);
  const [enProceso, setEnProceso] = useState<boolean>(false);
  type formHtml = React.FormEvent<HTMLFormElement>;

  const [totalPaginas, setTotalPaginas] = useState<number>(0);
  const [paginaActual, setPaginaActual] = useState<number>(0);

  const handlePaginaFinal = () => {
    if (totalPaginas > 0 && paginaActual < totalPaginas - 1) {
      obtenerAutorizaByPerson(totalPaginas - 1);
    }
  };

  const handlePaginaInicial = () => {
    if (paginaActual !== 0)
    {
      obtenerAutorizaByPerson(0);

    }
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 0) {
      obtenerAutorizaByPerson(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    if (paginaActual < totalPaginas - 1) {
      obtenerAutorizaByPerson(paginaActual + 1);
    }
  };



  let {
    idPersona,
    tipoUsuario,
    page,
    dobleEnlace,
    objeto,
  } = useFormulario<FilterAutoriza>(
    new FilterAutoriza("",0,0)
  );

  const [objAut, setObjAut] = useState<PersonaAutorizacion>(new PersonaAutorizacion( 0,0,0,"","","","","","","","","","","","","","","",new Date(), new Date(),0,"",0,0,0,0,0 ));

  const [arregloAutorizaByPerson, setArregloAutorizaByPerson] = useState<PersonaAutorizacion[]>([]);

  //idPersona = useParams<RouteParams>();
  //tipoUsuario = useParams<RouteParams>();


  
  
// Función para obtener AutorizaByPerson
  const obtenerAutorizaByPerson = async (page: number) => {
    let resultado;
    let url;
    if (idPersona.length > 0) {
      if (tipoUsuario != 0)
      {
        console.log("Entra ambos para responsable");
        url = ApiBack.AUTORIZACION_OBTENER_LIST_BY_AUTORIZACAP + "/" + idPersona + "/" + page;
      }else{
        console.log("Entra ambos para visitante");
        url = ApiBack.AUTORIZACION_OBTENER_LIST_BY_VISITANTECAP + "/" + idPersona + "/" + page;
      }
    }
    else{
      url = ApiBack.AUTORIZACION_TODASCAP + "/" + page;
    }

    resultado = await ServicioPrivado.peticionGET(url);

    setArregloAutorizaByPerson(resultado.content);

    setPaginaActual(resultado.pageable.pageNumber);
    setTotalPaginas(resultado.totalPages);

    setReady(true);
    return resultado;
  };

  useEffect(() => {
    if (!ready) {
      obtenerAutorizaByPerson(paginaActual);
    }
  }, []);

  const verifyForm = async (fh: formHtml) => {
    fh.preventDefault();
    fh.stopPropagation(); // evita las acciones del formulario
    obtenerAutorizaByPerson(0);

  }


  const formatFecha = (fechaDate: Date) => {
    console.log("FEcha a convertir " + fechaDate);
    const fechaObjeto = new Date(fechaDate);
    const dia = fechaObjeto.getDate().toString().padStart(2, "0");
    const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, "0"); // Se suma 1 porque los meses van de 0 a 11
    const año = fechaObjeto.getFullYear();

    // Crear la cadena con el formato DD-MM-YYYY
    const fechaFormateada = `${dia}-${mes}-${año}`;
    return fechaFormateada;
  }



  const borrarAutorizacion = async (codigoAutorizacion: any) => {
    const urlBorrar = ApiBack.AUTORIZACION_ELIMINAR + "/" + codigoAutorizacion;
    const resultado = await ServicioPrivado.peticionDELETE(urlBorrar);
    console.log(resultado);
    if (typeof resultado.message === "undefined") {
      MesajeError("No se puede eliminar la autorización.");
    } else {
      MesajeSuccess( "La autorización ha sido eliminada" );
    }
    obtenerAutorizaByPerson(paginaActual);
  };

  
  return (
    <main id="main" className="main">

      
    <div className="col-lg-11 mx-auto p-4 py-md-5">
      <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
        <i
          className="fa-regular fa-address-card fa-2xl"
          style={{ color: "#0a08bd" }}
        ></i>
        <span className="fs-4">Listado de Autorizaciones Activas</span>
      </header>
        <p className="fs-6 center align-content-center">

<div className="center align-content-center">
<div className="d-flex align-items-center justify-content-center">

                  <Form
                        noValidate
                        className="row g-3"
                        validated={enProceso}
                        onSubmit={verifyForm}
                      >

<div className="col-auto">
<Form.Group  controlId="tipoUsuario">


                  
                    <Form.Select
                      required
                      name="tipoUsuario"
                      value={tipoUsuario}
                      onChange={dobleEnlace}
                      className="form-control"
                    >
                      <option value={0}>Visitante</option>
                      <option value={1}>Responsable</option>
                    </Form.Select>
                  
                </Form.Group>
                </div>
                <div className="col-auto">
                <Form.Group controlId="idPersona">
                          
                          <Form.Control
                            required
                            type="text"
                            name="idPersona"
                            className="form-control"
                            value={idPersona}
                            onChange={dobleEnlace}
                            placeholder="Documento"
                          />
                          
                        </Form.Group>
                        </div>
                        <div className="col-auto">
                        <Button onClick={(e) => {
                          e.preventDefault();
                          obtenerAutorizaByPerson(0);
                        }}>Filtrar</Button>
                        </div>
                      </Form>
                      </div>
    
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                <th style={{ width: "3%" }}> </th>
                <th style={{ width: "9%" }}>Documento</th>
                  <th style={{ width: "13%" }}>Apellido</th>
                  <th style={{ width: "13%" }}>Nombre</th>
                  <th style={{ width: "9%" }}>Telefono</th>
                  <th style={{ width: "10%" }}>Correo</th>
                  <th style={{ width: "17%" }}>Motivo</th>
                  <th style={{ width: "8%" }}>Lugar</th>
                  <th style={{ width: "9%" }}>Fecha Ini</th>
                  <th style={{ width: "9%" }}>Fecha Fin</th>
                  <th style={{ width: "9%" }}>{" "}</th>
                </tr>
              </thead>
              <tbody>
                
              {arregloAutorizaByPerson.map((personaAutorizacion, indice) => (
                  <tr key={indice}>
                    <td>{indice + 1}</td>
                    <td>{personaAutorizacion.documentoAutorizado}</td>
                    <td>{personaAutorizacion.apellidosAutorizado}</td>
                    <td>{personaAutorizacion.nombreAutorizado}</td>
                    <td>{personaAutorizacion.telefonoAutorizado}</td>
                    <td>{personaAutorizacion.correoAutorizado}</td>
                    <td>{personaAutorizacion.motivo}</td>
                    <td>{personaAutorizacion.direccion}</td>
                    <td>{FormatFecha(personaAutorizacion.fechaInicio)}</td>
                    <td>{FormatFecha(personaAutorizacion.fechaFinal)} </td>
                    <td>
                      <Link to={"/dashboard/detailautoriza/" + personaAutorizacion.idAutorizaciones}>
                        <i className="fa-solid fa-magnifying-glass fa-sm"></i>
                      </Link>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>

            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
              <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaInicial();
                        }}>Inicio</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaAnterior();
                        }}>Anterior</a>
                </li>
                <li className="page-item">{ }</li>
                <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                        }}>{paginaActual + 1} de {totalPaginas}</a>
                
                <li className="page-item">{ }</li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaSiguiente();
                        }}>Siguiente</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" onClick={(e) => {
                          e.preventDefault();
                          handlePaginaFinal();
                        }}>Fin</a>
                </li>
              </ul>
            </nav>


            {/* Modal para eliminar */}
            {/* *********************************************************************************/}
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Eliminar Autorización</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                ¿Realmente desea eleminar la autorizacion?
                <br />
                <strong>
                {objAut.nombreAutorizado}  {objAut.apellidosAutorizado} - desde {objAut.fechaInicio.toString()} hasta {objAut.fechaFinal.toString()}
                </strong>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    setShow(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="danger"
                  onClick={(e) => {
                    borrarAutorizacion(objAut.idAutorizaciones);
                    setShow(false);
                  }}
                >
                  Eliminar
                </Button>
              </Modal.Footer>
            </Modal>
            {/* *********************************************************************************/}

          </div>
        </div>
      </div>
      </p>
          <div className="col-lg-11 mx-auto p-4 py-md-5">

          </div>
      </div>
    </main>

  );
};