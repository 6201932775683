import { useState, useEffect } from "react";

import ApiBack from "../../../utils/domines/ApiBack";

import ServicioPrivado from "../../../services/ServicioPrivado";
import { Link } from "react-router-dom";
import Dependencia from '../../../models/Dependencia';

export const ListDependencias = () => {
  //const [fecIni, setFecIni] = useState <Date>(new Date);
  //const [fecFin, setFecFin] = useState <Date>(new Date);
  const [fecAct, setFecAct] = useState<Date>(new Date());
  const [show, setShow] = useState(false);
  const [readyDep, setReadyDep] = useState(false);
  const handleClose = () => setShow(false);
  const [enProceso, setEnProceso] = useState<boolean>(false);
  type formHtml = React.FormEvent<HTMLFormElement>;

  const [objDep, setObjDep] = useState<Dependencia>(new Dependencia(0, ""));

  const [arregloDependencia, setArregloDependencia] = useState<Dependencia[]>(
    []
  );

  // Función para obtener AutorizaByPerson
  const obtenerDependencias = async () => {
    let url = ApiBack.DEPENDENCIA_OBTENERTODAS;
    const resultado = await ServicioPrivado.peticionGET(url);
    setArregloDependencia(resultado);
    setReadyDep(true);

    return resultado;
  };

  useEffect(() => {
    if (!readyDep) {
      obtenerDependencias();
    }
  }, []);

  return (
    <main id="main" className="main">
      <div className="col-lg-11 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
          <i
            className="fa-regular fa-address-card fa-2xl"
            style={{ color: "#0a08bd" }}
          ></i>
          <span className="fs-4">Listado de Unidades</span>
        </header>


        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}> </th>
                    <th style={{ width: "80%" }}>Dependencia</th>
                    <th style={{ width: "10%" }}> </th>
                  </tr>
                </thead>
                <tbody>
                  {arregloDependencia.map((datoDep, indice) => (
                    <tr key={indice}>
                      <td>{indice + 1}</td>
                      <td>{datoDep.nombre}</td>
                      <td>

                        <Link
                          to={"/dashboard/updatedep/" + datoDep.idDependencia}
                          title="Editar nombre"
                        >
                          <i
                            className="fa-regular fa-pen-to-square"
                            style={{ color: "#006600" }}
                            aria-label="Editar nombre"
                          ></i>
                        </Link>{" "}
                        <Link
                          to={"/dashboard/listdivisiones/" + datoDep.idDependencia}
                          title="Editar nombre"
                        >
                          <i
                            className="fa-solid fa-sitemap"
                            style={{ color: "#000066" }}
                            aria-label="Editar divisiones"
                          ></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          </div>
        </div>
        <div><a href="/dashboard/newdep">Agregar...</a></div>

        <div className="col-lg-11 mx-auto p-4 py-md-5"></div>
      </div>
    </main>
  );
};
