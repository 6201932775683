import { useState, useEffect, useContext } from "react";

import ApiBack from "../../../utils/domines/ApiBack";  

import ServicioPrivado from "../../../services/ServicioPrivado";
import Persona from "../../../models/Usuario";
import { ContextoUsuario } from "../../../security/ContextoUsuario";
import UsuarioLogin from "../../../models/UsuarioLogin";
import { useFormulario } from "../../../utils/hooks/useFormulario";
import { useParams } from "react-router-dom";
import { FormatFecha } from "../../../utils/tools/ToolsDate";

//const miUsuario = useContext(ContextoUsuario);
//const idUsuario = miUsuario?.autenticado.idUsuario;
// /utilidades/dominios/ApiBack";

export const DataUser = () => {


  const miUsuario = useContext(ContextoUsuario);
  const idUsuario =  String(miUsuario?.autenticado.idUsuario);


  const [arregloPersona, setArregloPersona] = useState<Persona[]>([]);


  const [enProceso, setEnProceso] = useState<boolean>(false);
  let {
    apellidos,
    correo,
    nombres,
    documento,
    foto,
    fechaInicio,
    fechaTermino,
    nombreRol,
    grado,
    objeto,
  } = useFormulario<UsuarioLogin>(new UsuarioLogin(0, "", "", "", "", "", "", new Date(), new Date(), "", ""));
  let { codigo } = useParams();
  const [todoListo, setTodoListo] = useState<boolean>(false);
  const [formatoFechaIni, setFormatoFechaIni] = useState<String>("-");
  const [formatoFechaFin, setFormatoFechaFin] = useState<String>("-");
  let cargaFinalizada = todoListo !== undefined;


  // Función para obtener productos
  const obtenerUsuario = async () => {
    const url = ApiBack.USUARIO_OBTENER + "/" + idUsuario;
    const resultado = await ServicioPrivado.peticionGET(url);

    

  // Obtener el formato local de la fecha


    objeto.apellidos = resultado.apellidos;
    objeto.nombres = resultado.nombres;
    objeto.documento = resultado.documento;
    objeto.correo = resultado.correo;
    objeto.foto = resultado.foto;
    objeto.fechaInicio = resultado.fechaInicio;
    objeto.fechaTermino = resultado.fechaTermino;
    objeto.nombreRol = resultado.nombreRol;
    objeto.grado = resultado.grado;
    


    if (objeto) {
      setTodoListo(true);
    }
    };
  // ************************************************************************


  useEffect(() => {
    if (!todoListo)
    {obtenerUsuario();}
  }, []);

  return (
      
      <div className="col-lg-12">
        <div className="card">
          <div id="imagenprevia" className="d-flex align-items-center justify-content-center">
            <img src={foto} alt="FOTO" id="imgPreView" height={155} width={"auto"}></img>
          </div>

          <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ width: "30%" }}> </th>
                  <th style={{ width: "70%" }}> </th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>Grado</td><td>{grado}</td>
                  </tr>
                
                  <tr>
                    <td>Documento</td><td>{documento}</td>
                  </tr>
                  <tr>
                    <td>Apellidos</td><td>{apellidos}</td>
                  </tr>
                  <tr>
                    <td>Nombres</td><td>{nombres}</td>
                  </tr>
                  <tr>
                    <td>Correo</td><td>{correo}</td>
                  </tr>
                  <tr>
                    <td>Fecha de Inicio</td><td>{FormatFecha(fechaInicio)}</td>
                  </tr>
                  <tr>
                    <td>Fecha de Termino</td><td>{FormatFecha(fechaTermino)}</td>
                  </tr>

                  <tr>
                    <td>Rol</td><td>{nombreRol}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
};