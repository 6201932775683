
class PersonalRol
{

  public idPersonaRol: number;
  public fechaInicio: Date;
  public idRol: number;
  public idPersona: number;

  constructor(id: number, idR: number, idP: number, fecIni: Date) {
    this.idPersonaRol = id;
    this.fechaInicio = fecIni;
    this.idPersona = idP;
    this.idRol = idR;
  }
}

export default PersonalRol;