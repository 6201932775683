
export const ExtracUrl = () => {
  //const url = window.location.href;
  const url = new URL(window.location.href);
    
  // Extrae el dominio de la URL y actualiza el estado
  const domain = url.hostname;

  //console.log(domain + " URL ACTUAL: " + url)

  return domain;
}
