import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServicioPublico from "../../services/ServicioPublico";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { useFormulario } from "../../utils/hooks/useFormulario";

import * as cifrado from "js-sha512";

import logoEnap from "../../../assets/image/logo_enap.png";
import { Col, Row } from "react-bootstrap";
import PersonaCompleta from "../../models/PersonaCompleta";
import Dependencia from "../../models/Dependencia";
import ApiBack from "../../utils/domines/ApiBack";
import ServicioPrivado from "../../services/ServicioPrivado";
import { MesajeError } from "../../utils/tools/MesajeError";
import Divisiones from "../../models/Divisiones";

// Para los mensajes, no olvidar !!!
// *******************************************************************
export const RegistroSesion = () => {
  // Variables
  // *******************************************************************
  const navigate = useNavigate();
  type formHtml = React.FormEvent<HTMLFormElement>;
  const [enProceso, setEnProceso] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<any>("");
  const [base64, setBase64] = useState<string>();
  const [arregloDependencia, setArregloDependencia] = useState<Dependencia[]>(
    []
  );
  const [readyUser, setReadyUser] = useState(false);

  const passRepRef = useRef<HTMLInputElement>(null);

  // Manejo de formulario con Hook personalizado (sin librerias externas)
  // *******************************************************************
  let {
    tipoDocumento,
    documento,
    grado,
    nombres,
    apellidos,
    telefono,
    password,
    correo,
    foto,
    status,
    edificio,
    numeroEdificio,
    idDependencia,
    idDivision,
    dobleEnlace,
    objeto,
  } = useFormulario<PersonaCompleta>(
    new PersonaCompleta(
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      0,
      new Date(),
      new Date(),
      "",
      "",
      "",
      "",
      0,
      0,
      0,
      3,
      "", 1
    )
  );

  const generarImage = (e: any) => {
    console.log("Llegada ", e);
    let file = e.target.files[0];
    console.log("File ", file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      //      reader.readAsDataURL(file);
      reader.onload = _handleReaderLoaded;
      // reader.readAsBinaryString(file)
      setImagePreview(reader.result);
    }
  };

  const _handleReaderLoaded = (readerEvt: any) => {
    let binaryString = readerEvt.target.result; //"data:image/png;base64," + readerEvt.target.result;
    objeto.foto = binaryString as string;
    setBase64(binaryString);
  };

  // Función flecha para resetear variables y limpiar cajas del formulario
  // *******************************************************************
  const limpiarCajas = (formulario: HTMLFormElement) => {
    formulario.reset();
    objeto.tipoDocumento = 1;
    objeto.documento = "";
    objeto.apellidos = "";
    objeto.nombres = "";
    objeto.correo = "";
    objeto.foto = "";
    objeto.telefono = "";
    objeto.password = "";
    objeto.grado = "";

    //formulario.tipoDocumento.value = "1";
    formulario.documento.value = "";
    formulario.apellidos.value = "";
    formulario.nombres.value = "";
    formulario.correo.value = "";
    //formulario.foto.value = "";
    formulario.telefono.value = "";
    formulario.password.value = "";
    formulario.grado.value = "";

    formulario.classList.remove("was-validated");
  };

  

  const verifyForm = async (fh: formHtml) => {
    /**
     * previene acciones por defecto de los formularios html
     */
    fh.preventDefault();
    setEnProceso(true);
    /**
     * se captura la información del formulario actual
     */
    const formActual = fh.currentTarget;
    formActual.classList.add("was-validated");
    if (formActual.checkValidity() === false) {
      fh.preventDefault();
      fh.stopPropagation(); // evita las acciones del formulario
    } else {
      //console.log("Conexión al backend");
      //const fromBackend = await ServicioPublico.createUser(objeto);
      //console.log(fromBackend);

      // Código para crear el usuario consumiendo servicio del back y usando sha512
      // *******************************************************************
      const claveCifrada = cifrado.sha512(objeto.password);
      objeto.password = claveCifrada;
      const resultado = await ServicioPublico.crearUsuario(objeto);

      if (resultado.idPersona) {

        limpiarCajas(formActual);

        navigate("/login");
        setEnProceso(false);
      } else {
        //limpiarCajas(formActual);
        //mensajeError();
        MesajeError("No se puede crear el usuario, verifique la información")
        //MensajeToastify("Error", "No se puede crear el usuario. Correo o perfil incorrectos.", 7000);
      }
    }
  };

  const obtenerDependencias = async () => {

    let resultado;
    let url = ApiBack.DEPENDENCIA_OBTENERTODAS;
    //console.log("url " + url);
    resultado = await ServicioPrivado.peticionGET(url);
    //console.log(resultado);
    if (resultado)
      setArregloDependencia(resultado);
    return resultado;
  };

  useEffect(() => {
    if (!readyUser) {
      obtenerDependencias();
    }
  }, []);

  const [selectedOption, setSelectedOption] = useState('');
  const [arrayDivisiones, setArrayDivisiones] = useState<Divisiones[]>([]);

  const handleFirstSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    idDependencia = Number(selectedValue);
    objeto.idDependencia = Number(selectedValue);
    objeto.idDivision = 1;
    

    let resultado;
    let url = ApiBack.DIVISION_OBTENERTODASBYDEP + "/" + idDependencia;
    console.log("url " + url);
    resultado = await ServicioPrivado.peticionGET(url);
    setArrayDivisiones(resultado);
  

  };



  return (
    <div>
      <main>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src={logoEnap} alt="" />
                      <span className="d-none d-lg-block">
                        Sistema de Ingreso ENAP
                      </span>
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Crear cuenta
                        </h5>
                        <p className="text-center small">
                          Complete la siguiente información personal
                        </p>
                      </div>

                      <Form
                        noValidate
                        className="row g-3"
                        validated={enProceso}
                        onSubmit={verifyForm}
                      >
                        <div className="col-12">
                          <Form.Group controlId="tipoDocumento">
                            <Form.Label>Tipo de documento</Form.Label>

                            <Form.Select
                              required
                              name="tipoDocumento"
                              value={tipoDocumento}
                              onChange={dobleEnlace}
                              className="form-control"
                            >
                              <option value="">
                                Seleccione el tipo de documento
                              </option>
                              <option value={1}>Cedula de Ciudadania</option>
                              <option value={2}>Cedula de extranjeria</option>
                              <option value={3}>Pasaporte</option>
                              <option value={4}>Tarjeta de Identidad</option>
                              <option value={5}>Visa</option>
                              <option value={6}>Otro</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Seleccione el tipo de documento
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="documento">
                            <Form.Label>Numero de documento</Form.Label>

                            <Form.Control
                              required
                              type="text"
                              name="documento"
                              className="form-control"
                              value={documento}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              Numero de documento es obligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="grado">
                            <Form.Label>Grado/Cargo/Escalafon</Form.Label>

                            <Form.Control
                              required
                              type="text"
                              name="grado"
                              className="form-control"
                              value={grado}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              Debe indicar un nombre de grado, cargo o escalafon
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="nombres">
                            <Form.Label>Nombre completo</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="nombres"
                              className="form-control"
                              value={nombres}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              Nombre es obligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="apellidos">
                            <Form.Label>Apellidos</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="apellidos"
                              className="form-control"
                              value={apellidos}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              Apellido es obligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="correo">
                            <Form.Label>Correo electrónico</Form.Label>
                            <div className="input-group has-validation">
                              <span className="input-group-text">@</span>
                              <Form.Control
                                required
                                type="email"
                                name="correo"
                                className="form-control"
                                value={correo}
                                onChange={dobleEnlace}
                              />
                              <Form.Control.Feedback type="invalid">
                                correo electrónico es obligatorio
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="telefono">
                            <Form.Label>Numero de telefono</Form.Label>
                            <div className="input-group has-validation">
                              <Form.Control
                                required
                                type="text"
                                name="telefono"
                                className="form-control"
                                value={telefono}
                                onChange={dobleEnlace}
                              />
                              <Form.Control.Feedback type="invalid">
                                correo electrónico es obligatorio
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="password">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                              required
                              type="password"
                              name="password"
                              className="form-control"
                              minLength={4}
                              value={password}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              Mínimo 4 caracteres
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group controlId="passRepRef">
                            <Form.Label>Confirmar contraseña</Form.Label>
                            <Form.Control
                              required
                              type="password"
                              name="passRepRef"
                              className="form-control"
                              ref={passRepRef}
                            />
                            <Form.Control.Feedback type="invalid">
                              Contraseñas no coindicen
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-6">
                          <Form.Group controlId="idDependencia">
                            <Form.Label>Unidad</Form.Label>

                            <Form.Select
                              required
                              name="idDependencia"
                              value={idDependencia}
                              onChange={handleFirstSelectChange}
                              className="form-control"
                            >
                              <option value="">
                                Seleccione la dependencia
                              </option>
                              {arregloDependencia.map((depData, indice) => ( 
                              <option value={depData.idDependencia}>{depData.nombre}</option>
                               ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Seleccione la unidad en la que labora/habita
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-6">
                          <Form.Group controlId="idDivision">
                            <Form.Label>Dependencia </Form.Label>

                            <Form.Select
                              required
                              name="idDivision"
                              value={idDivision}
                              onChange={dobleEnlace}
                              className="form-control"
                            >
                                <option value={1}>Seleccione division</option>
                                {arrayDivisiones.map((divData, indice) => ( 
                                <option value={divData.idDivision}>{divData.nombre}</option>
                                ))}
                              </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Seleccione la dependencia en la que labora/habita
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-6">
                          <Form.Group controlId="edificio">
                            <Form.Label>Direccion: Edificio</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="edificio"
                              className="form-control"
                              value={edificio}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              El nombre del edificio es obligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-6">
                          <Form.Group controlId="numeroEdificio">
                            <Form.Label>Numero/Oficina</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="numeroEdificio"
                              className="form-control"
                              value={numeroEdificio}
                              onChange={dobleEnlace}
                            />
                            <Form.Control.Feedback type="invalid">
                              El numero del edificio es obligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <Form.Group
                            as={Row}
                            className="mb-3"
                            controlId="filePhoto"
                          >
                            <Form.Label column sm={2}>
                              Foto:
                            </Form.Label>
                            <Col sm={10}>
                              <Form.Control
                                required
                                type="file"
                                name="filePhoto"
                                onChange={generarImage}
                              />
                              <Form.Control.Feedback type="invalid">
                                Seleccione una foto
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Crear cuenta
                          </button>
                        </div>
                        <div className="col-12">
                          <p className="small mb-0">
                            ¿Ya tienes una cuenta?{" "}
                            <Link to="/login">Clic aquí</Link>
                          </p>
                        </div>
                      </Form>
                      <div
                        id="imagenprevia"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <img
                          src={foto}
                          alt="FOTO"
                          id="imgPreView"
                          height={155}
                          width={"auto"}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
};
