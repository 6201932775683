class Usuario
{
  public idPersona: number;
  public tipoDocumento: number;
  public documento: string;
  public nombres: string;
  public apellidos: string;
  public telefono: string;
  public correo: string;
  public foto: string;
  public password: string;
  public grado: string;
  public status: number;

  constructor(id: number, tdoc: number, doc: string, nom: string, ape: string, tel: string, mail: string, fot: string, pas: string,grado: string, status: number)
  {
    this.idPersona = id;
    this.apellidos = ape;
    this.correo = mail;
    this.nombres = nom;
    this.tipoDocumento = tdoc;
    this.documento = doc;
    this.foto = fot;
    this.telefono = tel;
    this.password = pas;
    this.grado = grado;
    this.status = status;
  }
}

export default Usuario;