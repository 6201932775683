import React from 'react';
import logo from './logo.svg';
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { RuteoCompleto } from './app/utils/routes/RuteoCompleto';

// Carga Lazy
// ***********************************************************************************************
const loadingPage = (
  <div className="d-flex justify-content-center">
    <div className="mt-3">
      <button className="btn btn-primary" type="button" disabled>
        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" ></span>
        Cargando ...
      </button>
    </div>
  </div>
);
// ***********************************************************************************************

function App() {
  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={loadingPage}>
          <RuteoCompleto />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
