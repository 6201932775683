import fotoTripulante from "../../../assets/image/acercade.png";

export const AcercaDe = () => {
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Acerca de ...</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Inicio</a>
            </li>
            <li className="breadcrumb-item active">Acerca de</li>
          </ol>
        </nav>
      </div>

      <div className="mt-4">
        <h2>Sistema de Gestión de Ingreso ENAP</h2>
        <p>
          En este portal podra gestionar las autorizacoines de ingreso de personal invitado, empleados u otras personas externas a la ENAP, con el fin de ser coordinados con el CAP
        </p>
      </div>
    </main>
  );
};
