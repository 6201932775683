
class Divisiones
{

  public idDependencia: number;
  public idDivision: number;
  public nombre: string;

  constructor(idDivision: number, id: number, name: string)
  {
    this.idDependencia = id;
    this.nombre = name;
    this.idDivision = idDivision;
  }
}

export default Divisiones;