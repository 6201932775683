import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Bienvenida } from "../../containers/Bienvenida";
import { AcercaDe } from "../../views/shared/AcercaDe";
import { NoEncontrado } from "../../views/shared/NoEncontrado";
import { ShowListAutorizaciones } from "../../views/privadas/Autorizaciones/ShowListAutorizaciones";
import {CreateAutorizacion} from "../../views/privadas/Autorizaciones/CreateAutorizacion";
import { EditAutorizacion } from "../../views/privadas/Autorizaciones/EditAutorizacion";
import { ShowAutorizacion } from "../../views/privadas/Autorizaciones/ShowAutorizacion";
import { ListUser } from "../../views/privadas/users/ListUser";
import { ListAutorizaciones } from "../../views/privadas/Autorizaciones/ListAutorizaciones";
import { EditUser } from "../../views/privadas/users/EditUser";
import { UpdateUser } from "../../views/privadas/users/UpdateUser";
import { CreateUser } from "../../views/privadas/users/CreateUser";
import { ListAutorizacionesCap } from "../../views/privadas/Autorizaciones/ListAutorizacionesCap";
import { EditPassword } from "../../views/privadas/users/EditPassword";
import { EditPassUser } from "../../views/privadas/users/EditPassUser";
import { ListDependencias } from "../../views/privadas/dependencias/ListDependencias";
import { EditDependencia } from "../../views/privadas/dependencias/EditDependencia";
import { CreateDependencia } from "../../views/privadas/dependencias/CreateDependencia";
import { ListUserContra } from "../../views/privadas/blacklist/ListUserContra";
import { BlockUser } from "../../views/privadas/blacklist/BlockUser";
import { ListDetailUserContra } from "../../views/privadas/blacklist/ListDetailUserContra";
import { ListDivisiones } from "../../views/privadas/divisiones/ListDivisiones";
import { EditDivision } from "../../views/privadas/divisiones/EditDivision";
import { CreateDivision } from '../../views/privadas/divisiones/CreateDivision';


// Carga Lazy - Supenso
// ***********************************************************************************************
const cargando = (
  <div className="d-flex justify-content-center">
    <div className="mt-3">
      <button className="btn btn-primary" type="button" disabled>
        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" ></span>
        Cargando ...
      </button>
    </div>
  </div>
);
// ***********************************************************************************************

const RecursoNoEncontrado = lazy(() => import("../../views/shared/NoEncontrado").then(() => ({ default: NoEncontrado, })) );
const LazyBienvenida = lazy(() => import("../../containers/Bienvenida").then(() => ({ default: Bienvenida })) );
const LazyAcercaDe = lazy(() => import("../../views/shared/AcercaDe").then(() => ({ default: AcercaDe, })) );
const LazyListPermisos = lazy(() => import("../../views/privadas/Autorizaciones/ShowListAutorizaciones").then(() => ({ default: ShowListAutorizaciones, })) );
const LazyCreatePermisos = lazy(() => import("../../views/privadas/Autorizaciones/CreateAutorizacion").then(() => ({ default: CreateAutorizacion, })) );
const LazyEditPermisos = lazy(() => import("../../views/privadas/Autorizaciones/EditAutorizacion").then(() => ({ default: EditAutorizacion, })) );
const LazyShowPermisos = lazy(() => import("../../views/privadas/Autorizaciones/ShowAutorizacion").then(() => ({ default: ShowAutorizacion, })) );
const LazyShowPersonComplete = lazy(() => import("../../views/privadas/users/ListUser").then(() => ({ default: ListUser, })) );
const LazyListAutorizaciones = lazy(() => import("../../views/privadas/Autorizaciones/ListAutorizaciones").then(() => ({ default: ListAutorizaciones, })) );
const LazyListAutorizaCap = lazy(() => import("../../views/privadas/Autorizaciones/ListAutorizacionesCap").then(() => ({ default: ListAutorizacionesCap, })) );
const LazyEditUser = lazy(() => import("../../views/privadas/users/EditUser").then(() => ({ default: EditUser, })) );
const LazyUpdateUser = lazy(() => import("../../views/privadas/users/UpdateUser").then(() => ({ default: UpdateUser, })) );
const LazyCreateUser = lazy(() => import("../../views/privadas/users/CreateUser").then(() => ({ default: CreateUser, })) );
const LazyEditPassword = lazy(() => import("../../views/privadas/users/EditPassword").then(() => ({ default: EditPassword, })) );
const LazyEditPassUser = lazy(() => import("../../views/privadas/users/EditPassUser").then(() => ({ default: EditPassUser, })) );

const LazyListDependencia = lazy(() => import("../../views/privadas/dependencias/ListDependencias").then(() => ({ default: ListDependencias, })) );
const LazyEditDependencia = lazy(() => import("../../views/privadas/dependencias/EditDependencia").then(() => ({ default: EditDependencia, })) );
const LazyNewDependencia = lazy(() => import("../../views/privadas/dependencias/CreateDependencia").then(() => ({ default: CreateDependencia, })) );

const LazyListUserContra = lazy(() => import("../../views/privadas/blacklist/ListUserContra").then(() => ({ default: ListUserContra, })) );
const LazyBlockUser = lazy(() => import("../../views/privadas/blacklist/BlockUser").then(() => ({ default: BlockUser, })) );
const LazyListDetailUserContra = lazy(() => import("../../views/privadas/blacklist/ListDetailUserContra").then(() => ({ default: ListDetailUserContra, })) );
const LazyListDivisiones = lazy(() => import("../../views/privadas/divisiones/ListDivisiones").then(() => ({ default: ListDivisiones, })) );
const LazyEditDivision = lazy(() => import("../../views/privadas/divisiones/EditDivision").then(() => ({ default: EditDivision, })) );
const LazyCreateDivision = lazy(() => import("../../views/privadas/divisiones/CreateDivision").then(() => ({ default: CreateDivision, })) );


export const RuteoTablero = () => {
  return (
    <Suspense fallback={cargando}>
      <Routes>
        <Route path="/" element={<LazyBienvenida />} />
        <Route path="/about" element={<LazyAcercaDe />} />
        <Route path="/listPermisos" element={<LazyListPermisos />} />
        <Route path="/newPermiso" element={<LazyCreatePermisos />} />
        <Route path="/updateautoriza/:idAutorizacion" element={<LazyEditPermisos />} />
        <Route path="/detailautoriza/:idAutorizacion" element={<LazyShowPermisos />} />
        <Route path="/listUser" element={<LazyShowPersonComplete />} />
        <Route path="/listautoriza" element={<LazyListAutorizaciones />} />
        <Route path="/listcap" element={<LazyListAutorizaCap />} />
        
        <Route path="/editdataUser" element={<LazyEditUser />} />
        <Route path="/newUser" element={<LazyCreateUser />} />
        <Route path="/updateuser/:idUsuario" element={<LazyUpdateUser />} />
        <Route path="/editpassword" element={<LazyEditPassword />} />
        <Route path="/editpassuser" element={<LazyEditPassUser />} />

        <Route path="/listdep" element={<LazyListDependencia />} />
        <Route path="/newdep" element={<LazyNewDependencia />} />
        <Route path="/updatedep/:idDepSearch" element={<LazyEditDependencia />} />

        <Route path="/listUserContra" element={<LazyListUserContra />} />
        <Route path="/updateblack/:idUserSearch" element={<LazyBlockUser />} />
        <Route path="/detailblack/:idUserSearch" element={<LazyListDetailUserContra />} />

        <Route path="/listdivisiones/:idDepSearch" element={<LazyListDivisiones />} />
        <Route path="/updatediv/:idDivSearch" element={<LazyEditDivision />} />
        <Route path="/newdiv/:idDepSearch" element={<LazyCreateDivision />} />

        
        

        <Route path="*" element={<RecursoNoEncontrado />} />
      </Routes>
    </Suspense>
  );
};
