import { Link } from "react-router-dom";

import "./../../../assets/css/portada.css";
import "./../../../assets/css/carousel.css";

import logoEnap from "../../../assets/image/logo_enap.png";
import enap from "./../../../assets/image/logo_enap.png";
import arcescudo from "./../../../assets/image/arcescudo.png";
import mindefensa from "./../../../assets/image/mindefensa.jpg";

import fondo01 from "./../../../assets/image/panoramaENAP2.png";

export const Principal = () => {
  return (
    <div>
      {/* Barra de navegación: Inicio */}
      <header>
        <nav className="navbar navbar-expand-md fixed-top bg-light">
          <div className="container-fluid">
          <Link to="/" className="navbar-brand" >
          <img src={logoEnap} alt="" width={25} /> SINGENAP
          </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav me-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/#">
                    Inicio
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.armada.mil.co/">
                    Armada Nacional
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.escuelanaval.edu.co/">
                    Escuela Naval
                  </a>
                </li>
              </ul>
              <Link to="/login">
              <span className="navbar-text">Iniciar sesión</span>
              </Link>
              
            </div>
          </div>
        </nav>
      </header>
      {/* Barra de navegación: Fin */}

      <main>
        {/* Carousel: Inicio */}
        {/* *************************************************************** */}
        <div id="myCarousel" className="carousel slide align-content-center" data-bs-ride="carousel" >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
          </div>
          <div className="carousel-inner align-content-center">
            <div className="carousel-item active align-content-center">
              <img src={fondo01} alt="" />
              <div className="container">
                <div className="carousel-caption">
                  <h1>SINGENAP</h1>
                  <p>Sistema de autorización de ingreso de personal externo a las instalaciones ubicadas dentro de la Isla Naval</p>
                  <p>
                    <a className="btn btn-sm btn-primary" href="/login">
                      Iniciar Sesión
                    </a>
                  </p>
                </div>
              </div>
            </div>

            
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/* *************************************************************** */}
        {/* Carousel: Fin */}


        {/* Cuerpo página principal: Inicio */}
        {/* *************************************************************** */}
        <div className="container marketing">
          <div className="row">
            <div className="col-lg-4">
              <img
                src={enap}
                className="bd-placeholder-img "
                alt=""
                style={{width: "auto", height: "145px"}}
              />

              
              <p>Escuela Naval de Cadetes "Almirante Padilla"</p>
              <p>
                <a className="btn btn-secondary" href="https://www.escuelanaval.edu.co/">
                  Ir a
                </a>
              </p>
            </div>
            <div className="col-lg-4">
              <img
                src={arcescudo}
                className="bd-placeholder-img "
                alt=""
                style={{width: "auto", height: "145px"}}
              />

              
              <p>
                Armada Republica de Colombia
              </p>
              <p>
                <a className="btn btn-secondary" href="https://www.armada.mil.co/">
                  Ir a
                </a>
              </p>
            </div>
            <div className="col-lg-4">
            <img
                src={mindefensa}
                className="bd-placeholder-img "
                alt=""
                style={{width: "auto", height: "145px"}}
              />
              
              <p>
                Ministerio de Defensa
              </p>
              <p>
                <a className="btn btn-secondary" href="/#">
                  Ir a
                </a>
              </p>
            </div>
          </div>

          <hr className="featurette-divider" />
        </div>
        {/* *************************************************************** */}
        {/* Cuerpo página principal: Fin */}


        {/* Footer: Inicio */}
        {/* *************************************************************** */}
        <div>
          <footer className="container">
            <p className="float-end">
              <a href="/#">Inicio página</a>
            </p>
          </footer>
        </div>
        {/* *************************************************************** */}
        {/* Footer: Fin */}
      </main>
    </div>
  );
};

